import { z } from 'zod';
import { TJobSchema, TJobQuestionSchema, TJobVideoQuestionSchema } from '../table-schemas';
import { Endpoint, Method } from '../../../infrastructure';

export const JobDetailsSchema = TJobSchema.extend({
  skills: z.array(
    z.object({
      skill_id: z.number(),
      name: z.string(),
    }),
  ),
  questions: z.array(
    TJobQuestionSchema.extend({
      sort: z.number(),
    }),
  ),
  video_questions: z.array(
    TJobVideoQuestionSchema.extend({
      sort: z.number(),
    }),
  ),
});
export type JobDetails = z.infer<typeof JobDetailsSchema>;

export const GetJobDetailsParamsSchema = z.object({
  job_id: z.string(),
});
export type GetJobDetailsParams = z.infer<typeof GetJobDetailsParamsSchema>;

export type GetJobDetailsResponse = JobDetails;

export type GetJobDetailsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/jobs/:jobId',
  GetJobDetailsResponse,
  GetJobDetailsParams,
  undefined,
  {}
>;
