import { dateString } from '../../infrastructure';
import { TJobSchema, TJobVideoQuestionSchema } from '../jobs';
import { z } from 'zod';

export const JobVideoQuestionAscSchema = TJobSchema.pick({
  job_id: true,
})
  .merge(
    TJobVideoQuestionSchema.pick({
      video_question_id: true,
      mongo_id: true,
    }),
  )
  .extend({
    sort: z.number(),
    dtcreate: dateString,
    dtupdate: dateString,
  });
export type JobVideoQuestionAsc = z.infer<typeof JobVideoQuestionAscSchema>;
