import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { CreateJobQuestionRequestSchema } from './create-job-question';

export const UpdateJobQuestionRequestSchema = CreateJobQuestionRequestSchema;
export type UpdateJobQuestionRequest = z.infer<typeof UpdateJobQuestionRequestSchema>;

export type UpdateJobQuestionResponse = {};

export const UpdateJobQuestionParamsSchema = z.object({
  job_question_id: z.number(),
});
export type UpdateJobQuestionParams = z.infer<typeof UpdateJobQuestionParamsSchema>;

export type UpdateJobQuestionEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql/job-question/:question_id`,
  UpdateJobQuestionResponse,
  UpdateJobQuestionParams,
  undefined,
  UpdateJobQuestionRequest
>;
