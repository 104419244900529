import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { StaffingCandidate, StaffingCandidateSchema } from '../base';

export const UpdateStaffingCandidateRequestSchema = StaffingCandidateSchema.pick({
  rating: true,
  status: true,
  is_approved: true,
})
  .partial({
    rating: true,
    status: true,
    is_approved: true,
  })
  .extend({
    replace_current_a: z.boolean().nullish(),
    user: z
      .object({
        name: z.string().optional(),
        email: z.string().optional(),
      })
      .optional(),
  });

export type UpdateStaffingCandidateRequest = z.infer<typeof UpdateStaffingCandidateRequestSchema>;

export type UpdateStaffingCandidateResponse = StaffingCandidate;

export const UpdateStaffingCandidateParamsSchema = z.object({
  staffing_candidate_id: z.coerce.number(),
  opportunity_id: z.string().optional(),
});
export type UpdateStaffingCandidateParams = z.infer<typeof UpdateStaffingCandidateParamsSchema>;

export type UpdateStaffingCandidateEndpoint = Endpoint<
  Method.PUT,
  `/api/v3/staffing-candidate/:${UpdateStaffingCandidateParams['staffing_candidate_id']}`,
  UpdateStaffingCandidateResponse,
  UpdateStaffingCandidateParams,
  undefined,
  UpdateStaffingCandidateRequest
>;
