import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const PatchJobSortingRequestSchema = z.object({
  n_sort: z.number(),
});
export type PatchJobSortingRequest = z.infer<typeof PatchJobSortingRequestSchema>;

export type PatchJobSortingResponse = {};

export const PatchJobSortingParamsSchema = z.object({
  job_id: z.string(),
});
export type PatchJobSortingParams = z.infer<typeof PatchJobSortingParamsSchema>;

export type PatchJobSortingEndpoint = Endpoint<
  Method.PATCH,
  `/api/v2/sql/jobs/:job_id/sort`,
  PatchJobSortingResponse,
  PatchJobSortingParams,
  undefined,
  PatchJobSortingRequest
>;
