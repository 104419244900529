import { AssignmentSchema } from '../base';
import { z } from 'zod';

export const AssignmentUpdateSchema = AssignmentSchema.pick({
  assignment_id: true,
  dtstart: true,
  dtend: true,
  category: true,
  enddatecertainty: true,
  isflexible: true,
  notes: true,
})
  .partial({
    dtstart: true,
    dtend: true,
    category: true,
    enddatecertainty: true,
    isflexible: true,
    notes: true,
  })
  .extend({
    is_overlap_confirmed: z.boolean().nullish(),
    user: z
      .object({
        person: z.string(),
        name: z.string(),
        email: z.string(),
      })
      .nullish(),
  });

export type AssignmentUpdate = z.infer<typeof AssignmentUpdateSchema>;
