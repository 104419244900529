import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const WebSFContactSchema = z.object({
  web_sf_contact_id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  name: z.string(),
  email_address: z.string(),
  email_validation_status: z.string().nullable(),
  phone_number: z.string(),
  company: z.string().nullable(),
  job_title: z.string().nullable(),
  message: z.string(),
  subscribe: z.boolean().nullable(),
  agree_privacy_policy: z.boolean(),
  inquiry_type: z.string(),
  gclid: z.string().nullable(),

  ip_address: z.string().nullable(),
  territory: z.string().nullable(),
  timezone: z.string(),

  browser_device: z.string().nullable(),
  page_url: z.string().nullable(),

  google_user_id: z.string().nullable(),

  utm_medium: z.string().nullable(),
  utm_source: z.string().nullable(),
  utm_campaign: z.string().nullable(),
  utm_term: z.string().nullable(),

  dt_created: dateString,
});
export type WebSFContact = z.infer<typeof WebSFContactSchema>;
