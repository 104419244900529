import { TJobQuestionSchema, TJobSchema } from '../jobs';
import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const JobQuestionAscSchema = TJobSchema.pick({
  job_id: true,
})
  .merge(
    TJobQuestionSchema.pick({
      question_id: true,
    }),
  )
  .extend({
    sort: z.number(),
    dtcreate: dateString,
    dtupdate: dateString,
  });

export type JobQuestionAsc = z.infer<typeof JobQuestionAscSchema>;
