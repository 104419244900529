import { Endpoint, Method } from '../../../infrastructure';
import { StaffingCandidateVote } from '../base';
import { StaffingCandidateVoteCreateSchema } from '../crud';
import { z } from 'zod';

export const StaffingCandidateVoteCreateRequestSchema = StaffingCandidateVoteCreateSchema.pick({
  reason: true,
  staffing_candidate_id: true,
  vote: true,
}).extend({
  email: z.string(),
});
export type StaffingCandidateVoteCreateRequest = z.infer<
  typeof StaffingCandidateVoteCreateRequestSchema
>;

export type StaffingCandidateVoteCreateResponse = StaffingCandidateVote;

export type StaffingCandidateVoteCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/staffing-candidate-votes/`,
  StaffingCandidateVoteCreateResponse,
  undefined,
  undefined,
  StaffingCandidateVoteCreateRequest
>;
