import { z } from 'zod';

import { PositionPostgresSchema } from '../';

export const PositionDeleteSchema = PositionPostgresSchema.pick({
  position_id: true,
  deleted_at: true,
  deleted_by: true,
});
export type PositionDelete = z.infer<typeof PositionDeleteSchema>;
