import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TJobCategorySchema } from '../../jobs';

export const PatchJobCategorySortingRequestSchema = TJobCategorySchema.pick({
  n_sort: true,
});
export type PatchJobCategorySortingRequest = z.infer<typeof PatchJobCategorySortingRequestSchema>;

export type PatchJobCategorySortingResponse = {};

export const PatchJobCategorySortingParamsSchema = z.object({
  job_category_id: z.number(),
});
export type PatchJobCategorySortingParams = z.infer<typeof PatchJobCategorySortingParamsSchema>;

export type PatchJobCategorySortingEndpoint = Endpoint<
  Method.PATCH,
  `/api/v2/sql/job-categories/:job_category_id/sort`,
  PatchJobCategorySortingResponse,
  PatchJobCategorySortingParams,
  undefined,
  PatchJobCategorySortingRequest
>;
