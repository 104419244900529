import { Endpoint, Method } from '../../../infrastructure';
import { SQLPosition, SQLPositionSchema } from '../base';
import { z } from 'zod';

export const PositionDuplicateRequestSchema = SQLPositionSchema.pick({
  position_id: true,
}).extend({
  num_copies: z.number(),
  project_id: z.string().nullish() //Used in the FE
});
export type PositionDuplicateRequest = z.infer<typeof PositionDuplicateRequestSchema>;

export type PositionDuplicateResponse = SQLPosition[];

export type PositionDuplicateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/positions-new/duplicate`,
  PositionDuplicateResponse,
  undefined,
  undefined,
  PositionDuplicateRequest
>;
