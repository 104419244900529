import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const RemoveJobQuestionParamsSchema = z.object({
  job_id: z.string(),
});
export type RemoveJobQuestionParams = z.infer<typeof RemoveJobQuestionParamsSchema>;

export type RemoveJobQuestionEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/sql/job-question/:question_id`,
  {},
  RemoveJobQuestionParams,
  undefined,
  undefined
>;
