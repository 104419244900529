import React from 'react';

import { Typography, Tooltip } from 'antd';

import './index.less';

export const PillItemDescription = ({
  description,
  header,
}: {
  description?: string;
  header: string;
}) => {
  return (
    <>
      <b>{header}</b>
      <br />
      <i>{description}</i>
    </>
  );
};

type PillItemType = {
  header: string;
  headerDescription?: React.ReactNode;
  value: React.ReactNode;
  secondaryValue?: string;
  description?: React.ReactNode;
  secondaryDescription?: React.ReactNode;
  accent?: boolean;
  headerTooltip?: string;
  progressive?: boolean;
};

interface PillItemProps extends PillItemType {}

interface PillProps {
  items: PillItemType[];
}

const PillItem: React.FC<PillItemProps> = ({
  header,
  headerDescription,
  value,
  secondaryValue,
  description,
  secondaryDescription,
  accent,
  progressive,
}) => {
  const renderValueWithTooltip = (
    value: React.ReactNode,
    tooltip?: React.ReactNode,
    isSecondary = false,
  ) => {
    const className = `pill-item-value${accent ? ' accent' : ''} ${
      progressive && !isSecondary ? 'progressive' : ''
    }`;
    const content = <div className={className}>{value}</div>;
    return tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content;
  };

  return (
    <div className="pill-item">
      {headerDescription ? (
        <Tooltip title={headerDescription}>
          <div className={`pill-item-header${accent ? ' accent' : ''}`}>
            <Typography.Text>{header}</Typography.Text>
          </div>
        </Tooltip>
      ) : (
        <div className={`pill-item-header${accent ? ' accent' : ''}`}>
          <Typography.Text>{header}</Typography.Text>
        </div>
      )}
      {renderValueWithTooltip(value, description)}
      {secondaryValue && renderValueWithTooltip(secondaryValue, secondaryDescription, true)}
    </div>
  );
};

const Pill: React.FC<PillProps> = ({ items }) => (
  <div className="pill">
    {items.map((item, index) => (
      <PillItem key={index} {...item} />
    ))}
  </div>
);

export default Pill;
