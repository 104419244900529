import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

import { TRecruiterFocus, TRecruiterFocusSchema } from '../table-schema';

export const UpdateRecruiterFocusParamsSchema = TRecruiterFocusSchema.pick({
  recruiter_id: true,
});

export const UpdateRecruiterFocusRequestSchema = z.object({
  focus_amount: z.number(),
});

export type UpdateRecruiterFocusParams = z.infer<typeof UpdateRecruiterFocusParamsSchema>;

export type UpdateRecruiterFocusQuery = {};

export type UpdateRecruiterFocusRequest = z.infer<typeof UpdateRecruiterFocusRequestSchema>;

export type UpdateRecruiterFocusResponse = TRecruiterFocus;

export type UpdateRecruiterFocusEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql/recruiter-focus/${UpdateRecruiterFocusParams['recruiter_id']}`,
  UpdateRecruiterFocusResponse,
  UpdateRecruiterFocusParams,
  UpdateRecruiterFocusQuery,
  UpdateRecruiterFocusRequest
>;
