import { z } from 'zod';
import { StaffingCandidateVoteSchema } from '../base';

export const StaffingCandidateVoteCreateSchema = StaffingCandidateVoteSchema.pick({
  staffing_candidate_id: true,
  person_id: true,
  reason: true,
  vote: true,
});
export type StaffingCandidateVoteCreate = z.infer<typeof StaffingCandidateVoteCreateSchema>;
