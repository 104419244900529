import { Form, Col, DatePicker, Grid } from 'antd';
import { FilterAdapterProps, FilterItem } from '../../types';
import { formatDateValue } from '../../utils/date';

import dayjs from 'dayjs';
import { useRef, useState } from 'react';

const DefaultFormat = 'YYYY-MM';

interface MonthProps {
  // Format display value
  format?: string;
  // Format filter value
  formatValue?: string;
  allowClear?: boolean;
  onChange?: (value?: string) => void;
}

const { MonthPicker } = DatePicker;

interface FilterMonthProps extends FilterItem, MonthProps {}
interface DateAdapterProps
  extends Omit<MonthProps, 'onChange'>,
    FilterAdapterProps<string | undefined> {}

const MonthAdapter = ({
  value,
  onChange,
  format = DefaultFormat,
  formatValue,
  allowClear,
}: DateAdapterProps) => {
  const input = useRef<HTMLInputElement | null>(null);
  const [openPicker, setOpenPicker] = useState(false);
  const screens = Grid.useBreakpoint();
  const isMobile = (screens.xs || screens.sm || screens.md) && !screens.lg;

  const onClick = () => {
    setOpenPicker(!openPicker);
    input.current?.blur();
  };

  return (
    <MonthPicker
      ref={input as any}
      value={dayjs(formatDateValue(value)?.toDate())}
      format={format}
      allowClear={allowClear}
      onChange={(date) => onChange && onChange(date?.format(formatValue || format))}
      onClick={isMobile ? onClick : undefined}
      onFocus={isMobile ? () => input.current?.blur() : undefined}
      open={isMobile ? openPicker : undefined}
      onOpenChange={isMobile ? setOpenPicker : undefined}
      placement="topLeft"
    />
  );
};

const FilterDate = ({
  name,
  label,
  span,
  format,
  formatValue,
  allowClear,
  onChange,
}: FilterMonthProps) => {
  return (
    <Col span={span}>
      <Form.Item name={name} label={label}>
        <MonthAdapter
          format={format}
          formatValue={formatValue}
          allowClear={allowClear}
          onChange={onChange}
        />
      </Form.Item>
    </Col>
  );
};

export default FilterDate;
