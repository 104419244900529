import { z } from 'zod';
import {
  Endpoint,
  FilterOperations,
  makeSorter,
  Method,
  ReportResponse,
  ReportsQuerySchema,
} from '../../infrastructure';
import { stringToBoolean } from '../../infrastructure/schema';
import { AppDetailSchema } from '../application';

export const GetPosAppMatchDetailItemSchema = AppDetailSchema.pick({
  applicant_id: true,
  application_id: true,
  name: true,
  email: true,
  availability: true,
  seniority: true,
  english_level: true,
  yoe: true,
  status: true,
  dt_applied: true,
  excitement_rate: true,
  country: true,
  country_code: true,
  city: true,
  state: true,
  timezone: true,
  timezone_number: true,
  timezone_short: true,
  latitude: true,
  longitude: true,
  expected_contract_type: true,
  brains_id: true,
  stage_id: true,
  cost: true,
  salary_currency: true,
  cost_currency: true,
  usd_salary: true,
  currency_salary: true,
}).extend({
  recruiter: z
    .object({
      applicant_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  author: z
    .object({
      applicant_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  job: z
    .object({
      job_id: z.string(),
      job_name: z.string(),
      description: z.string(),
    })
    .nullable(),
  project_id: z.string(),
  position_id: z.string(),
  current_stage: z.string(),
  is_stale: z.boolean(),
  is_aged: z.string(),
  pos_skills: z.array(z.string()),
  app_skills: z.array(z.string()),
});
export type GetPosAppMatchDetailItem = z.infer<typeof GetPosAppMatchDetailItemSchema>;

export const GetPosAppMatchDetailFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  is_stale: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  is_aged: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  current_stage: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  skills: z.record(z.enum([FilterOperations.CONTAIN]), z.string().array()).optional(),
});
export type GetPosAppMatchDetailFilters = z.infer<typeof GetPosAppMatchDetailFiltersSchema>;

export const GetPosAppMatchDetailSorterEnumSchema = z.enum([
  'dt_applied',
  'status',
  'cost',
  'status',
  'seniority',
  'yoe',
  'english_level',
  'excitement_rate',
]);
export type GetPosAppMatchDetailSorterEnum = z.infer<typeof GetPosAppMatchDetailSorterEnumSchema>;
export const GetPosAppMatchDetailSorterSchema = makeSorter(GetPosAppMatchDetailSorterEnumSchema);
export type GetPosAppMatchDetailSorter = z.infer<typeof GetPosAppMatchDetailSorterSchema>;

//Query
export const GetPosAppMatchDetailQuerySchema = ReportsQuerySchema.extend({
  filters: GetPosAppMatchDetailFiltersSchema.optional(),
  sorter: GetPosAppMatchDetailSorterSchema.optional(),
});
export type GetPosAppMatchDetailQuery = z.infer<typeof GetPosAppMatchDetailQuerySchema>;

export type GetPosAppMatchDetailResponse = ReportResponse<GetPosAppMatchDetailItem>;

export type GetPosAppMatchDetailEndpoint = Endpoint<
  Method.GET,
  `/api/v2/positions/pos-app-match`,
  GetPosAppMatchDetailResponse,
  undefined,
  GetPosAppMatchDetailQuery,
  undefined
>;
