import { SkillSchema } from '../skill';
import { TJobSchema } from '../jobs';
import { z } from 'zod';

export const JobSkillAscSchema = TJobSchema.pick({
  job_id: true,
}).merge(
  SkillSchema.pick({
    skill_id: true,
  }),
);
export type JobSkillAsc = z.infer<typeof JobSkillAscSchema>;
