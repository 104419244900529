import { Endpoint, Method } from '../../../infrastructure';
import { Assignment, AssignmentSchema } from '../base';
import { AssignmentUpdateSchema } from '../crud';
import { z } from 'zod';

export const AssignmentUpdateRequestSchema = AssignmentUpdateSchema.pick({
  category: true,
  dtend: true,
  dtstart: true,
  enddatecertainty: true,
  isflexible: true,
  notes: true,
  user: true,
  is_overlap_confirmed: true,
});
export type AssignmentUpdateRequest = z.infer<typeof AssignmentUpdateRequestSchema>;

export const AssignmentUpdateParamsSchema = AssignmentSchema.pick({
  assignment_id: true,
});
export type AssignmentUpdateParams = z.infer<typeof AssignmentUpdateParamsSchema>;

export type AssignmentUpdateResponse = Assignment;

export type AssignmentUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/assignments-new/${AssignmentUpdateParams['assignment_id']}`,
  AssignmentUpdateResponse,
  AssignmentUpdateParams,
  undefined,
  AssignmentUpdateRequest
>;
