import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';
import { JobStatusSchema } from '../base';

export const ListJobsSlimFiltersSchema = z
  .object({
    title: z.record(z.enum([FilterOperations.CONTAIN]), z.string()),
    category: z.record(z.enum([FilterOperations.IN]), z.string().array()),
  })
  .partial();
export type ListJobsSlimFilters = z.infer<typeof ListJobsSlimFiltersSchema>;

export const ListJobsSlimQuerySchema = z.object({
  filters: ListJobsSlimFiltersSchema.optional(),
});
export type ListJobsSlimQuery = z.infer<typeof ListJobsSlimQuerySchema>;

export const ListJobsSlimItemSchema = z.object({
  job_id: z.string(),
  title: z.string(),
  status: JobStatusSchema,
  department_id: z.string(),
  job_category: z.string(),
});
export type ListJobsSlimItem = z.infer<typeof ListJobsSlimItemSchema>;

export type ListJobsSlimResponse = ListJobsSlimItem[];

export type ListJobsSlimEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/jobs/slim',
  ListJobsSlimResponse,
  {},
  ListJobsSlimQuery,
  {}
>;
