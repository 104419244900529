import { z } from 'zod';
import { Endpoint, Method, stringToBoolean } from '../../infrastructure';

export type ListPeopleByDepartmentEndpoint = Endpoint<
  Method.GET,
  `/api/v2/people/department/:departmentId`,
  ListPeopleByDepartmentResponse,
  ListPeopleByDepartmentParams,
  ListPeopleByDepartmentQuery,
  undefined
>;

export type ListPeopleByDepartmentParams = {
  departmentId: string;
};

export const ListPeopleByDepartmentQuerySchema = z
  .object({
    active: stringToBoolean.optional(),
  })
  .optional();
export type ListPeopleByDepartmentQuery = z.infer<typeof ListPeopleByDepartmentQuerySchema>;

export type PeopleByDepartment = {
  person_id: string;
  name: string;
  email: string;
  slack_internal_id: string;
};

export type ListPeopleByDepartmentResponse = PeopleByDepartment[];
