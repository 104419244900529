import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';
import { StaffingCandidateSchema } from '../base';
import { AppDetailSchema } from '../../application';
import { StaffingCandidateVoteExtendedSchema } from '../../staffing-candidate-vote';

export const AppCandidateDetailSchema = AppDetailSchema.pick({
  application_id: true,
  status: true,
  contract_status: true,
  name: true,
  email: true,
  availability: true,
  seniority: true,
  english_level: true,
  yoe: true,
  dt_applied: true,
  excitement_rate: true,
  country: true,
  country_code: true,
  city: true,
  state: true,
  timezone: true,
  timezone_number: true,
  timezone_short: true,
  latitude: true,
  longitude: true,
  expected_contract_type: true,
}).extend({
  recruiter: z
    .object({
      applicant_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  author: z
    .object({
      applicant_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  job: z
    .object({
      job_id: z.string(),
      job_name: z.string(),
      description: z.string(),
    })
    .nullable(),
  current_stage: z.string().nullable(),
  person_id: z.string().nullable(),
});

export const PersonDetailSchema = z.object({
  person_id: z.string(),
  name: z.string(),
  contract_type: z.string(),
  yoe: z.number().nullable(),
  country_code: z.string().nullable(),
  timezone: z.string().nullable(),
  country: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
});
export const VotesSchema = StaffingCandidateVoteExtendedSchema.array();

export const ListStaffingCandidateItemSchema = StaffingCandidateSchema.pick({
  position_staffing_id: true,
  staffing_candidate_id: true,
  staffing_recruiter_id: true,
  rating: true,
  person_id: true,
  application_id: true,
  status: true,
  is_approved: true,
  dt_created: true,
  dt_updated: true,
}).extend({
  cost: z.number().nullable(),
  usd_salary: z.number().nullable(),
  currency: z.string().nullable(),
  currency_cost: z.number().nullable(),
  currency_salary: z.number().nullable(),
  lead_id: z.string(),
  position_id: z.string(),
  app_detail: AppCandidateDetailSchema.nullable(),
  person_detail: PersonDetailSchema.nullable(),
  name: z.string(),
  votes: VotesSchema.nullable(),
});

export type ListStaffingCandidateItem = z.infer<typeof ListStaffingCandidateItemSchema>;

export const ListStaffingCandidateFiltersSchema = z.object({
  lead_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  application_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  person_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  leads_id: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
});
export type ListStaffingCandidateFilters = z.infer<typeof ListStaffingCandidateFiltersSchema>;

export const ListStaffingCandidateQuerySchema = z.object({
  filters: ListStaffingCandidateFiltersSchema.optional(),
});
export type ListStaffingCandidateQuery = z.infer<typeof ListStaffingCandidateQuerySchema>;

export type ListStaffingCandidateResponse = ListStaffingCandidateItem[];

//Endpoint
export type ListStaffingCandidateEndpoint = Endpoint<
  Method.GET,
  '/api/v3/staffing-candidates/',
  ListStaffingCandidateResponse,
  undefined,
  ListStaffingCandidateQuery,
  undefined
>;
