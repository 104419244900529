import { z } from 'zod';
import { TJobQuestionSchema } from '../table-schemas';
import {
  Endpoint,
  Method,
  FilterOperations,
  ReportsQuerySchema,
  ReportResponse,
} from '../../../infrastructure';

export const ListJobQuestionsItemSchema = z.object({
  question_id: TJobQuestionSchema.shape.question_id,
  label: TJobQuestionSchema.shape.label,
  description: TJobQuestionSchema.shape.description,
  code: TJobQuestionSchema.shape.code,
  info: TJobQuestionSchema.shape.info,
  fields: TJobQuestionSchema.shape.fields,
});
export type ListJobQuestionsItem = z.infer<typeof ListJobQuestionsItemSchema>;

export const ListJobQuestionsFiltersSchema = z
  .object({
    job_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    label: z.record(z.enum([FilterOperations.CONTAIN]), z.string()),
    question_id: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
  })
  .partial();
export type ListJobQuestionsFilters = z.infer<typeof ListJobQuestionsFiltersSchema>;

export const ListJobQuestionsQuerySchema = ReportsQuerySchema.extend({
  filters: ListJobQuestionsFiltersSchema.optional(),
});
export type ListJobQuestionsQuery = z.infer<typeof ListJobQuestionsQuerySchema>;

export type ListJobQuestionsResponse = ReportResponse<ListJobQuestionsItem>;

export type ListJobQuestionsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/job-questions',
  ListJobQuestionsResponse,
  {},
  ListJobQuestionsQuery,
  {}
>;
