import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

export const JobFamilyMoveSchema = z.object({
  parent_category_id: z.number(),
  job_family_ids: z.array(z.number()),
});

export type JobFamilyMove = z.infer<typeof JobFamilyMoveSchema>;

export type MoveJobFamilyParams = {};

export type MoveJobFamilyQuery = {};

export type MoveJobFamilyRequest = z.infer<typeof JobFamilyMoveSchema>;

export type MoveJobFamilyResponse = {};

export type MoveJobFamilyEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql/job-families/move`,
  MoveJobFamilyResponse,
  MoveJobFamilyParams,
  MoveJobFamilyQuery,
  MoveJobFamilyRequest
>;
