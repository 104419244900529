import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

import { TRecruiterFocus, TRecruiterFocusSchema } from '../table-schema';

export const CreateRecruiterFocusSchema = TRecruiterFocusSchema.pick({
  focus_amount: true,
  recruiter_id: true,
});

export type CreateRecruiterFocusParams = {};

export type CreateRecruiterFocusQuery = {};

export type CreateRecruiterFocusRequest = z.infer<typeof CreateRecruiterFocusSchema>;

export type CreateRecruiterFocusResponse = TRecruiterFocus;

export type CreateRecruiterFocusEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql/recruiter-focus`,
  CreateRecruiterFocusResponse,
  CreateRecruiterFocusParams,
  CreateRecruiterFocusQuery,
  CreateRecruiterFocusRequest
>;
