import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const UploadOppExtensionRevenueRequestSchema = z.object({
  fileUpstream: z.any().optional(),
});

export type UploadOppExtensionRevenueRequest = z.infer<
  typeof UploadOppExtensionRevenueRequestSchema
>;

export type UploadOppExtensionRevenueEndpoint = Endpoint<
  Method.POST,
  '/api/v2/project/upload-opp-extension-revenue',
  {},
  undefined,
  undefined,
  UploadOppExtensionRevenueRequest
>;

export const UploadOppExtensionRevenueRowSchema = z.object({
  project_serial_id: z.number(),
  ymonth: z.number(),
  revenue: z.number(),
  team_size: z.number().nullable(),
});
export type UploadOppExtensionRevenueRow = z.infer<typeof UploadOppExtensionRevenueRowSchema>;

export const UploadOppExtensionRevenueRowErrorSchema = z.object({
  project_serial_id: z.number(),
  ymonth: z.number(),
  revenue: z.number(),
  team_size: z.number().nullable(),
  errorMessage: z.string(),
});
export type UploadOppExtensionRevenueRowError = z.infer<
  typeof UploadOppExtensionRevenueRowErrorSchema
>;

export const UploadOppExtensionRevenueResponseSchema = z.object({
  total: z.number(),
  processed: z.number(),
  failures: z.number(),
  failedRecords: z.array(UploadOppExtensionRevenueRowErrorSchema),
});
export type UploadOppExtensionRevenueResponse = z.infer<
  typeof UploadOppExtensionRevenueResponseSchema
>;
