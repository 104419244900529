import React, { useCallback, useMemo } from 'react';
import qs from 'qs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from '@shared/hooks';

import { Popover, Row, Col, Select, Space, Button } from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useApplicationQuickSearch } from '@src/queries/ApplicationSearch/queries';

import moment from 'moment';

import { oneLineSummary } from './utils';
import { ApplicationQuickSearchItem } from '@ct-internal/api';
import QuickSearchV2NoContentPlaceholder from './QuickSearchV2NoContentPlaceholder';

import '@components/Shared/StatusesDropDown/StatusesDropDown.less';

interface QuickSearchProps {
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
}

const QuickSearchV2: React.FC<QuickSearchProps> = (props) => {
  const history = useHistory();
  const [textQuery, setTextQuery] = useState<string>('');

  const debouncedTextQuery = useDebounce(textQuery, 500);

  // Query hook for searching applications
  const { data, isLoading } = useApplicationQuickSearch({
    request: { textQuery: debouncedTextQuery },
    options: { enabled: debouncedTextQuery.length >= 3 },
  });

  // Memoized item renderer
  const itemRenderer = useCallback((application: ApplicationQuickSearchItem) => {
    const appId = application.applicationId;
    return {
      value: appId,
      label: (
        <div className="search-statuses-dropdown">
          <Row>
            <Col span={16}>
              <b>{application.name}</b>
            </Col>
            <Col span={8}>{application.jobShortName}</Col>
          </Row>
          <Row style={{ color: '#aaa' }}>
            <Col span={16}>{oneLineSummary(application)}</Col>
            <Col span={8}>
              <div className={`application-status-${application.statusId}`}>
                <div className="application-status-inner-container">
                  <span className="application-badge" />
                  <span>{application.statusHuman}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ color: '#aaa' }}>
            <Col span={24}>
              <small>
                Last interaction:{' '}
                {moment(application.updatedAt).format('ddd, MMM Do YYYY, h:mm:ss a')}
              </small>
            </Col>
          </Row>
        </div>
      ),
    };
  }, []);

  // Memoized search results
  const searchResults = useMemo(() => data?.map(itemRenderer) ?? [], [data, itemRenderer]);

  const handleSearch = useCallback((value: string) => {
    if (!value || value.length < 3) {
      return;
    }
    setTextQuery(value);
  }, []);

  const goToManageApplications = useCallback(() => {
    const searchParams = {
      kw: textQuery,
      search: {
        kw: textQuery,
      },
    };

    const route = `/admin/candidates?${qs.stringify(searchParams)}`;
    history.push(route);
  }, [history, textQuery]);

  const handleSelect = useCallback(
    (item: any) => {
      props.onBlur?.();

      if (item && item.value) {
        history.push(`/admin/application/${item.value}`);
      } else {
        goToManageApplications();
      }
    },
    [props, history, goToManageApplications],
  );

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && searchResults.length > 0) {
      handleSelect(searchResults[0]);
    }
  };

  const handleButtonClick = useCallback(() => {
    goToManageApplications();
  }, [goToManageApplications]);

  const shouldShowContent = textQuery && textQuery.length >= 3;
  const notFoundContent =
    shouldShowContent && (isLoading || !searchResults || searchResults.length === 0);

  return (
    <Space.Compact style={{ width: '100%', maxWidth: '600px' }}>
      <Popover
        title="Search instructions"
        trigger="hover"
        overlayStyle={{
          maxWidth: '500px',
        }}
        content={
          <div>
            <ul style={{ listStyleType: 'upper-roman !important' }}>
              <li>
                To search for a applicant’s name just enter the name (or some letters of his name)
              </li>
              <li>
                To search for a applicant’s email, just enter the applicant’s full email address
                including the @ sign and the full domain
              </li>
              <li>
                To search for a applicant’s linkedIn, just enter the LinkedIn URL into the search
                bar
              </li>
              <li>
                To search by keywords start the search string with “kw:” and follow with the single
                word you are searching. To search multiple words use “+” for “AND” operation, and
                use “,” for “OR”. You may use spaces for clarity, but not in the middle of a
                searched word. Search examples:
                <ul style={{ listStyleType: 'circle !important' }}>
                  <li>To search for an applicant with both java and senior: "kw: java+senior"</li>
                  <li>
                    To search applications that mention Ford or Mercedez (or both): "kw: ford,
                    mercedes"
                  </li>
                </ul>
              </li>
            </ul>
            <b>
              By default, the search bar ignores rejected applications. To include them in the
              search add the term "include:rejected"
            </b>
          </div>
        }
      >
        <InfoCircleOutlined style={{ marginRight: 10 }} />
      </Popover>
      <Select
        showSearch
        defaultActiveFirstOption={true}
        value={null}
        showArrow={false}
        filterOption={false}
        options={searchResults}
        labelInValue
        loading={isLoading}
        placeholder={props.placeholder ?? 'Search applications...'}
        onSearch={handleSearch}
        onSelect={handleSelect}
        onFocus={props.onFocus}
        onInputKeyDown={handleKeyDown}
        onBlur={props.onBlur}
        style={props.style ?? { width: '100%' }}
        className={'quick-search'}
        popupClassName={'quick-search-dropdown'}
        notFoundContent={
          notFoundContent && (
            <QuickSearchV2NoContentPlaceholder searchResult={searchResults} isLoading={isLoading} />
          )
        }
      />
      <Button
        className={'quick-search-button'}
        type="primary"
        icon={<SearchOutlined />}
        onClick={handleButtonClick}
      />
    </Space.Compact>
  );
};

export default QuickSearchV2;
