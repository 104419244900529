import { Endpoint, Method } from '../../../infrastructure';
import { StaffingCandidateVote } from '../base';
import { StaffingCandidateVoteUpdateSchema } from '../crud';
import { z } from 'zod';

export const StaffingCandidateVoteUpdateRequestSchema = StaffingCandidateVoteUpdateSchema.pick({
  reason: true,
  vote: true,
});
export type StaffingCandidateVoteUpdateRequest = z.infer<
  typeof StaffingCandidateVoteUpdateRequestSchema
>;

export const StaffingCandidateVoteUpdateParamsSchema = StaffingCandidateVoteUpdateSchema.pick({
  staffing_candidate_vote_id: true,
});
export type StaffingCandidateVoteUpdateParams = z.infer<
  typeof StaffingCandidateVoteUpdateParamsSchema
>;

export type StaffingCandidateVoteUpdateResponse = StaffingCandidateVote;

export type StaffingCandidateVoteUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/staffing-candidate-votes/${StaffingCandidateVoteUpdateParams['staffing_candidate_vote_id']}`,
  StaffingCandidateVoteUpdateResponse,
  StaffingCandidateVoteUpdateParams,
  undefined,
  StaffingCandidateVoteUpdateRequest
>;
