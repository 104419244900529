import { Endpoint, Method } from '../../../infrastructure';
import { StaffingCandidateVoteExtended } from '../base';
import { StaffingCandidateVoteFindSchema } from '../crud';
import { z } from 'zod';

export const StaffingCandidateVoteListFiltersSchema = StaffingCandidateVoteFindSchema;
export type StaffingCandidateVoteListFilters = z.infer<
  typeof StaffingCandidateVoteListFiltersSchema
>;

export const StaffingCandidateVoteListQuerySchema = z.object({
  filters: StaffingCandidateVoteListFiltersSchema.optional(),
});
export type StaffingCandidateVoteListQuery = z.infer<typeof StaffingCandidateVoteListQuerySchema>;

export type StaffingCandidateVoteListResponse = StaffingCandidateVoteExtended[];

export type StaffingCandidateVoteListEndpoint = Endpoint<
  Method.GET,
  '/api/v2/staffing-candidate-votes/',
  StaffingCandidateVoteListResponse,
  undefined,
  StaffingCandidateVoteListQuery,
  undefined
>;
