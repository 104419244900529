import { z } from 'zod';

export const TRecruiterFocusSchema = z.object({
  recruiter_id: z.string(),
  focus_amount: z.number(),
  dtcreate: z.number(),
  dtupdate: z.number().nullable(),
});

export type TRecruiterFocus = z.infer<typeof TRecruiterFocusSchema>;

export const TRecruiterMonthlyFocusSchema = z.object({
  recruiter_id: z.string(),
  ymonth: z.number(),
  focus_amount: z.number(),
  dtcreate: z.number(),
  dtupdate: z.number().nullable(),
});

export type TRecruiterMonthlyFocus = z.infer<typeof TRecruiterMonthlyFocusSchema>;
