import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TJobCategory } from '../../jobs/table-schemas';

export const JobCategoryDeleteParamsSchema = z.object({
  job_category_id: z.coerce.number(),
});

export type DeleteJobCategoryParams = z.infer<typeof JobCategoryDeleteParamsSchema>;

export type DeleteJobCategoryQuery = {};

export type DeleteJobCategoryRequest = {};

export type DeleteJobCategoryResponse = TJobCategory;

export type DeleteJobCategoryEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/sql/job-categories/${DeleteJobCategoryParams['job_category_id']}`,
  DeleteJobCategoryResponse,
  DeleteJobCategoryParams,
  DeleteJobCategoryQuery,
  DeleteJobCategoryRequest
>;
