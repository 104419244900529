import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { RevenueRateSchema } from '../../revenue-rate';

export const PositionBulkRevenueRequestSchema = RevenueRateSchema.pick({
  dtfrom: true,
  revenueamount: true,
  project_id: true,
}).extend({
  positions_id: z.string().array(),
});

export type PositionBulkRevenueRequest = z.infer<typeof PositionBulkRevenueRequestSchema>;

export type PositionBulkRevenueResponse = {};

export type PositionBulkRevenueEndpoint = Endpoint<
  Method.POST,
  `/api/v2/positions-new/bulk-revenue`,
  PositionBulkRevenueResponse,
  undefined,
  undefined,
  PositionBulkRevenueRequest
>;
