const SENTRY_URL =
  'https://94687c8f11298542647ec45026cd214b@o36721.ingest.us.sentry.io/4508438254714880';
const APM_RUM_URL = 'https://1197fbcaeec14244ac3e54f027ff1bf7.apm.us-east-1.aws.cloud.es.io:443';
const GOOGLE_CLIENT_ID = '40323449079-ianvo4sdc6hdjk14bahjlkodcrgmiq8b.apps.googleusercontent.com';
const GOOGLE_MAPS_API_KEY = 'AIzaSyD5AWKpU6iq_uecwhzvRDs3wt0yf-I4oAI';
const SLACK_INTERNAL_TEAM = 'C02KGL8TV';
const SMARTLOOK_ID = '2688b1a7379052f18e67d9d734d0284ef2a0a534';
const SMARTLOOK_REGION = 'us';
const LINEAR_API_KEY = 'lin_api_sdzBvATJm5XCEC1hErwkmt5QWM0GbdewoZsGLAZg';
const TINY_MCE_API_KEY = 'efjjt3m4q30mqh4qwl4mizwelpfsx1xp9m55q4tcsqqcx097';

const url_prefix = process.env.URL_PREFIX
  ? process.env.URL_PREFIX
  : process.env.REACT_URL_PREFIX || '';

const envs = {
  local: {
    ENV: 'local',
    API_BASE: `https://${process.env.REACT_APP_HIRE_HOST || 'local.cleverbuild.biz'}:3100`,
    BRAINS_API_BASE: `https://${process.env.REACT_APP_HIRE_HOST || 'local.cleverbuild.biz'}:8043`,
    FRONTEND_BASE: `https://${process.env.REACT_APP_HIRE_HOST || 'local.cleverbuild.biz'}:5000`,
    WEBSITE_BASE: `http://localhost:3000`,
    APM_RUM_NAME: 'cleverhire-app',
    // APM_RUM_URL,
    // SENTRY_URL,
    GOOGLE_ANALYTICS: 'XXXXXXXXXX',
    GOOGLE_TAG_MANAGER: '',
    GOOGLE_CLIENT_ID,
    GOOGLE_MAPS_API_KEY,
    POWER_BI_AZURE_TENANT_ID: 'b050914a-863e-4fac-aeea-d103797cdc93',
    POWER_BI_AZURE_CLIENT_ID: '1d1dff4b-bb9a-4a31-bf4c-b77a447fc2c0',
    brainsUrl: `https://${process.env.REACT_APP_BRAINS_HOST || 'local.cleverbuild.biz'}:8043`,
    NEST_API_BASE: 'https://local.cleverbuild.biz:3200',
    AUTH_API_BASE: 'https://local.cleverbuild.biz:3400',
    SLACK_INTERNAL_TEAM,
    SMARTLOOK_ID,
    SMARTLOOK_REGION,
    LINEAR_API_KEY,
    TINY_MCE_API_KEY,
  },
  e2e: {
    ENV: 'local',
    API_BASE: 'http://localhost:3100',
    BRAINS_API_BASE: 'http://localhost:8043',
    FRONTEND_BASE: 'http://localhost:5000',
    WEBSITE_BASE: 'http://localhost:3000',
    APM_RUM_NAME: 'cleverhire-app',
    // APM_RUM_URL,
    // SENTRY_URL,
    GOOGLE_CLIENT_ID,
    GOOGLE_ANALYTICS: 'XXXXXXXXXX',
    GOOGLE_TAG_MANAGER: '',
    GOOGLE_MAPS_API_KEY,
    POWER_BI_AZURE_TENANT_ID: 'b050914a-863e-4fac-aeea-d103797cdc93',
    POWER_BI_AZURE_CLIENT_ID: '1d1dff4b-bb9a-4a31-bf4c-b77a447fc2c0',
    brainsUrl: 'http://localhost:8043',
    AUTH_API_BASE: 'https://local.cleverbuild.biz:3400',
    NEST_API_BASE: 'https://local.cleverbuild.biz:3200',
    SLACK_INTERNAL_TEAM,
    SMARTLOOK_ID,
    SMARTLOOK_REGION,
    LINEAR_API_KEY,
    TINY_MCE_API_KEY,
  },
  dev: {
    ENV: 'development',
    API_BASE: `https://${url_prefix}hire-api.clevertech.dev`,
    BRAINS_API_BASE: `https://${url_prefix}brains-api.clevertech.dev`,
    FRONTEND_BASE: 'https://hire.clevertech.dev',
    WEBSITE_BASE: 'https://lumenalta-website-git-dev-clevertech.vercel.app',
    APM_RUM_NAME: 'cleverhire-app',
    // APM_RUM_URL,
    SENTRY_URL,
    GOOGLE_CLIENT_ID,
    GOOGLE_ANALYTICS: 'XXXXXXXXXX',
    GOOGLE_TAG_MANAGER: '',
    GOOGLE_MAPS_API_KEY,
    POWER_BI_AZURE_TENANT_ID: 'b050914a-863e-4fac-aeea-d103797cdc93',
    POWER_BI_AZURE_CLIENT_ID: '1d1dff4b-bb9a-4a31-bf4c-b77a447fc2c0',
    brainsUrl: `https://${url_prefix}brains.clevertech.dev`,
    NEST_API_BASE: `https://${url_prefix}clevertech-api.clevertech.dev`,
    AUTH_API_BASE: `https://${url_prefix}auth-api.clevertech.dev`,
    SLACK_INTERNAL_TEAM,
    SMARTLOOK_ID,
    SMARTLOOK_REGION,
    LINEAR_API_KEY,
    TINY_MCE_API_KEY,
  },
  prod: {
    ENV: 'production',
    API_BASE: 'https://hire-api.clevertech.biz',
    BRAINS_API_BASE: 'https://brains-api.clevertech.biz',
    FRONTEND_BASE: 'https://hire.clevertech.biz',
    WEBSITE_BASE: 'https://lumenalta.com',
    APM_RUM_NAME: 'cleverhire-app',
    APM_RUM_URL,
    SENTRY_URL,
    GOOGLE_ANALYTICS: 'G-ZE8JMJG00C',
    GOOGLE_TAG_MANAGER: 'GTM-KBLTCBKM',
    GOOGLE_CLIENT_ID,
    GOOGLE_MAPS_API_KEY,
    POWER_BI_AZURE_TENANT_ID: 'b050914a-863e-4fac-aeea-d103797cdc93',
    POWER_BI_AZURE_CLIENT_ID: '1d1dff4b-bb9a-4a31-bf4c-b77a447fc2c0',
    brainsUrl: 'https://brains.clevertech.biz',
    NEST_API_BASE: 'https://clevertech-api.clevertech.biz',
    AUTH_API_BASE: 'https://auth-api.clevertech.biz',
    SLACK_INTERNAL_TEAM,
    SMARTLOOK_ID,
    SMARTLOOK_REGION,
    LINEAR_API_KEY,
    TINY_MCE_API_KEY,
  },
};

const localHostnames = ['local.cleverbuild.biz', 'localhost', '127.0.0.1', 'hire-react'];

const calculateConfig = () => {
  const envFromEnvirontmentVar = process.env.CONFIG_ENV || process.env.REACT_CONFIG_ENV;
  if (envFromEnvirontmentVar) {
    return envs[envFromEnvirontmentVar];
  }

  if (typeof window === 'undefined') {
    return envs['local'];
  }

  const { hostname } = window.location;
  if (localHostnames.indexOf(hostname) >= 0) {
    return envs['local'];
  }

  const hostnameParts = hostname.split('.');
  const subdomain = hostnameParts[0];
  if (subdomain === 'hire' && hostnameParts[hostnameParts.length - 1] === 'biz') {
    return envs['prod'];
  }

  const env = hostnameParts[hostnameParts.length - 1];
  const config = envs[env];
  return config;
};

const config = calculateConfig();

export default config;
