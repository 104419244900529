import { z } from 'zod';
import { PositionCreateRequestSchema } from './create';
import { SQLPosition } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const PositionBulkCreateRequestSchema = PositionCreateRequestSchema.extend({
  num_positions: z.number(),
});
export type PositionBulkCreateRequest = z.infer<typeof PositionBulkCreateRequestSchema>;

export type PositionBulkCreateResponse = SQLPosition[];

export type PositionBulkCreateEndpoint = Endpoint<
  Method.POST,
  '/api/v2/positions-new/bulk-create',
  PositionBulkCreateResponse,
  undefined,
  undefined,
  PositionBulkCreateRequest
>;
