import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const ListJobTitleFilterOptionsItemSchema = z.object({
  job_id: z.string(),
  job_family_title: z.string(),
  job_suffix: z.string(),
});
export type ListJobTitleFilterOptionsItem = z.infer<typeof ListJobTitleFilterOptionsItemSchema>;

export type ListJobTitleFilterOptionsResponse = ListJobTitleFilterOptionsItem[];

export type ListJobTitleFilterOptionsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/jobs/title-filter-options',
  ListJobTitleFilterOptionsResponse,
  {},
  undefined,
  {}
>;
