import { z } from 'zod';
import { AssignmentSchema } from '../base';

export const AssignmentFindSchema = AssignmentSchema.pick({
  position_id: true,
  project_id: true,
  assignment_id: true,
  dtstart: true,
  dtend: true,
}).partial();
export type AssignmentFind = z.infer<typeof AssignmentFindSchema>;
