import { z } from 'zod';

import {
  Endpoint,
  Method,
  makeSorter,
  FilterOperations,
  ReportsQuerySchema,
  ReportResponse,
} from '../../../infrastructure';

import { JobStatusSchema, OwnerExpandedSchema } from '../../jobs/base';
import { TJobSchema, TJobCategorySchema, TJobPrioritySchema } from '../../jobs/table-schemas';
import { OnDeckReportJobCountryCodeSchema, OnDeckReportJobMatchSchema } from './applications';

export const OnDeckReportJobItemSchema = TJobSchema.pick({
  job_id: true,
  title: true,
  job_suffix: true,
  slug: true,
  job_short_name: true,
  level: true,
  dtcreate: true,
  dtupdate: true,
}).extend({
  on_deck_visibility: z.boolean(),
  priority: TJobPrioritySchema.shape.name, // Hot, Ev
  status: JobStatusSchema,
  owner: OwnerExpandedSchema.nullable(),
  application_counts: z.object({
    missing_summaries: z.number(),
    application_count: z.number(),
    br_applications: z.number(),
    us_ca_applications: z.number(),
    eu_applications: z.number(),
    other_applications: z.number(),
    exact_matches: z.number(),
    close_matches: z.number(),
    partial_matches: z.number(),
    stage1_count: z.number(),
    stage2_count: z.number(),
    stage3_count: z.number(),
    stage4_count: z.number(),
    stage5_count: z.number(),
  }),
});

export type OnDeckReportJobItem = z.infer<typeof OnDeckReportJobItemSchema>;

export const OnDeckReportJobFamilyItemSchema = z.object({
  job_family_id: z.number(),
  title: z.string(),
  category: TJobCategorySchema.shape.name.nullable(),
  sub_category: TJobCategorySchema.shape.name.nullable(),
  num_jobs: z.number(),
  num_applications: z.number(),
  jobs: z.array(OnDeckReportJobItemSchema),
});

export type OnDeckReportJobFamilyItem = z.infer<typeof OnDeckReportJobFamilyItemSchema>;

export const OnDeckReportFiltersSchema = z
  .object({
    job_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    category_ids: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    level_ids: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    match_type: z.record(z.enum([FilterOperations.IN]), OnDeckReportJobMatchSchema.array()),
    is_gem: z.record(z.enum([FilterOperations.EQUAL]), z.enum(['true', 'false'])),
    country_code: z.record(z.enum([FilterOperations.IN]), OnDeckReportJobCountryCodeSchema.array()),
    recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    salary_min: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number()),
    salary_max: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
  })
  .partial();

export type OnDeckReportFilters = z.infer<typeof OnDeckReportFiltersSchema>;

export const OnDeckReportSorterSchema = makeSorter(
  z.enum(['title', 'category', 'num_jobs', 'num_applications']),
);

export type OnDeckReportSorter = z.infer<typeof OnDeckReportSorterSchema>;

export const OnDeckReportQuerySchema = ReportsQuerySchema.extend({
  filters: OnDeckReportFiltersSchema.optional(),
  sorter: OnDeckReportSorterSchema.optional(),
});

export type OnDeckReportQuery = z.infer<typeof OnDeckReportQuerySchema>;

export type OnDeckReportResponse = ReportResponse<OnDeckReportJobFamilyItem>;

export type OnDeckReportEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/on-deck/report',
  OnDeckReportResponse,
  {},
  OnDeckReportQuery,
  {}
>;
