import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TJobVideoQuestionAnswerSchema } from '../table-schemas';

export const CreateJobVideoQuestionAnswerRequestSchema = z.object({
  video_question_id: TJobVideoQuestionAnswerSchema.shape.video_question_id,
  application_id: TJobVideoQuestionAnswerSchema.shape.application_id,
  video_mp4: TJobVideoQuestionAnswerSchema.shape.video_mp4,
  thumb: TJobVideoQuestionAnswerSchema.shape.thumb.optional(),
});

export type CreateJobVideoQuestionAnswerRequest = z.infer<
  typeof CreateJobVideoQuestionAnswerRequestSchema
>;

export const CreateJobVideoQuestionAnswerResponseSchema = z.object({
  answer_id: TJobVideoQuestionAnswerSchema.shape.answer_id,
});
export type CreateJobVideoQuestionAnswerResponse = z.infer<
  typeof CreateJobVideoQuestionAnswerResponseSchema
>;

export type CreateJobVideoQuestionAnswerEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql/job-video-questions/answer`,
  CreateJobVideoQuestionAnswerResponse,
  undefined,
  undefined,
  CreateJobVideoQuestionAnswerRequest
>;
