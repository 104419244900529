import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';

export const SqlSkillGroupSchema = z.object({
  id: z.number(),
  group: z.string(),
  color: z.string(),
});
export type SqlSkillGroup = z.infer<typeof SqlSkillGroupSchema>;
export const SkillSchema = z.object({
  skill_id: z.number(),
  mongo_skill_id: z.string(),
  name: z.string(),
  seq: z.number().nullable(),
  recruiting_mode: z.string().nullable(),
  internal_sourcing: z.boolean().nullable(),
  description: z.string().nullable(),
  groups: z.array(SqlSkillGroupSchema),
  dt_created: dateString,
  dt_updated: dateString.nullable(),
});

export type Skill = z.infer<typeof SkillSchema>;

export const SkillMongoSchema = z.object({
  _id: z.string(),
  skill: z.string(),
  recruitingMode: z.string().optional(),
  description: z.string().optional(),
  order: z.number().optional(),
  internalSourcing: z.boolean().optional(),
  group: z.string(),
  updated_at: z.string(),
  created_at: z.string(),
});
export type SkillMongo = z.infer<typeof SkillMongoSchema>;
