import { z } from 'zod';

import { PsnRctrSchema } from '../psn-rctr/base';
import { ReportResponse } from '../../infrastructure';

export const ListRecruiterFocusesMetadataSchema = z.object({
  previous_ymonth: z.number(),
  current_ymonth: z.number(),
  next_ymonth: z.number(),
  ymonth_window: z.array(z.number()),
});

export const ListRecruiterFocusesRawItemSchema = PsnRctrSchema.extend({
  recruiter_id: z.string(),
  focus_amount: z.number(),
  name: z.string(),
  history: z.array(
    z.object({
      ymonth: z.number(),
      actual: z.number(),
    }),
  ),
  overrides: z.array(
    z.object({
      ymonth: z.number(),
      goal: z.number(),
    }),
  ),
});

export const ListRecruiterFocusesItemSchema = PsnRctrSchema.extend({
  recruiter_id: z.string(),
  name: z.string(),
  history: z.array(
    z.object({
      ymonth: z.number(),
      actual: z.number(),
      goal: z.number(),
    }),
  ),
  previous_month: z.object({
    actual: z.number(),
    goal: z.number(),
  }),
  current_month: z.object({
    actual: z.number(),
    goal: z.number(),
  }),
  next_month_goal: z.number(),
});

export type ListRecruiterFocusesMetadata = z.infer<typeof ListRecruiterFocusesMetadataSchema>;

export type ListRecruiterFocusesRawItem = z.infer<typeof ListRecruiterFocusesRawItemSchema>;

export type ListRecruiterFocusesItem = z.infer<typeof ListRecruiterFocusesItemSchema>;

export type ListRecruiterFocusesRawReport = ReportResponse<ListRecruiterFocusesRawItem> & {
  metadata: ListRecruiterFocusesMetadata;
};

export type ListRecruiterFocusesReport = ReportResponse<ListRecruiterFocusesItem>;
