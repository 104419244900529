import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

const TestLinkSchema = z.object({
  self: z.object({
    href: z.string(),
  }),
});

const TechTestSchema = z.object({
  id: z.string(),
  name: z.string(),
  skills: z.array(z.string()),
  durationInMinutes: z.number(),
  _links: TestLinkSchema,
});

const PageSchema = z.object({
  size: z.number(),
  totalElements: z.number(),
  totalPages: z.number(),
  number: z.number(),
});

// Define the response schema matching the actual API response
export const GetTechTestsResponseSchema = z.object({
  _embedded: z.object({
    tests: z.array(TechTestSchema),
  }),
  page: PageSchema,
});

export type TechTest = z.infer<typeof TechTestSchema>;
export type GetTechTestsResponse = z.infer<typeof GetTechTestsResponseSchema>;

export const GetTechTestsQuerySchema = z
  .object({
    count: z.number().optional(),
  })
  .optional();

export type GetTechTestsQuery = z.infer<typeof GetTechTestsQuerySchema>;

export type GetTechTestsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/tech-tests',
  GetTechTestsResponse,
  undefined,
  GetTechTestsQuery,
  undefined
>;
