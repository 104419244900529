import { z } from 'zod';
import { BaseSchemaDW } from '../structure';
import { ExpectedContractType } from '../../modules/application';

export const StatusTypeSchema = z.object({
  name: z.string(),
  id: z.string(),
  optgroup: z.string(),
  status: z.number().nullish(),
  aggregates: z.array(z.number()).nullish(),
});
export type StatusType = z.infer<typeof StatusTypeSchema>;
//We don't have users for the status yet.
const ApplicationStatusesSchema = z.object({
  dt_interviewing: z.date().nullable(),

  dt_req_interview1: z.date().nullable(),
  //req_interview1_by: z.string().nullable(),

  dt_req_interview2: z.date().nullable(),
  //req_interview2_by: z.string().nullable(),

  dt_test: z.date().nullable(),
  // test_by: z.string().nullable(),

  dt_pending: z.date().nullable(),
  // pending_by: z.string().nullable(),

  dt_decision: z.date().nullable(),
  // decision_by: z.string().nullable(),

  dt_rejected: z.date().nullable(),
  //rejected_by: z.string().nullable(),

  dt_passive_relationship: z.date().nullable(),
  /// passive_relationship_by: z.string().nullable(),

  dt_active_relationship: z.date().nullable(),
  /// active_relationship_by: z.string().nullable(),

  dt_moved_on: z.date().nullable(),
  /// moved_on_by: z.string().nullable(),

  dt_applicant_declined: z.date().nullable(),

  dt_unresponsive: z.date().nullable(),

  dt_offered: z.date().nullable(),
  // oferred_by: z.string().nullable(),

  dt_hired: z.date().nullable(),
  // hired_by: z.string().nullable(),

  dt_hired_and_onboarded: z.date().nullable(),

  // decision: z.string().nullable(),

  dt_current_status: z.date().nullable(),
  current_status: z.number().nullable(),
  current_stage: z.number().nullable(),
  cycle_id: z.number().nullable(),
});
export type ApplicationStatuses = z.infer<typeof ApplicationStatusesSchema>;

export const ApplicationDWSchema = z
  .object({
    application_id: z.number().nullable(),
    app_mongo_id: z.string(),
    job_id: z.string().nullable(),
    applicant_id: z.number().nullable(),
    requested_salary: z.number(),
    agreed_salary: z.number(),
    salary_currency: z.string().nullish(),
    lead_experience: z.boolean(),
    seniority: z.number().nullable(),
    english_level: z.number().nullable(),
    excitement_rate: z.number().nullable(),
    yoe: z.number().nullable(),
    country: z.string().nullable(),
    timezone: z.string().nullable(),
    weeks_can_start: z.number(),
    submited_voice_note: z.boolean().nullable(),
    owner: z.string().nullable(),
    dt_applied: z.date(),
    applicant_referred_by: z.string().nullable(),
    sourcing_recruiter: z.string().nullable(),
    recruiting_source: z.string().nullable(),
    utm_source: z.string().nullable(),
    utm_medium: z.string().nullable(),
    found_via: z.string().nullable(),
    tech_interviewer: z.string().nullable(),
    cycle_id: z.number(),
    reviewed_by: z.string().nullable(),
    automated_english_level: z.number().nullable(),
    automated_english_score: z
      .object({
        pronunciation: z.number(),
        fluency: z.number(),
        grammar: z.number(),
        coherence: z.number(),
        vocab: z.number(),
        overall: z.number(),
      })
      .nullable(),
    dt_reviewed: z.date().nullable(),
    auto_rejected: z.boolean().nullable(),
  })
  .merge(ApplicationStatusesSchema)
  .merge(BaseSchemaDW);
export type ApplicationDW = z.infer<typeof ApplicationDWSchema>;

export const StatusHistorySchema = z.object({
  _id: z.string(),
  oldStatus: z.number().nullable(),
  endDate: z.string().nullable(),
  newStatus: z.number(),
  startDate: z.string(),
});
export type StatusHistory = z.infer<typeof StatusHistorySchema>;

export const ApplicationSkillLevelSchema = z.object({
  _id: z.string(),
  skill: z.string(),
  level: z.number(),
  favorite: z.boolean(),
  name: z.string(),
});

export type ApplicationSkillLevel = z.infer<typeof ApplicationSkillLevelSchema>;

export const ApplicationAssignmentSChema = z.object({
  _id: z.string(),
  lead: z.string(),
  position: z.string(),
  name: z.string(),
  status: z.number(),
  optionForAssignment: z.number(),
  order: z.number(),
  createdBy: z.string(),
});

export const ApplicationMongoSchema = z.object({
  _id: z.string(),
  job: z.string(),
  user: z.string(),
  //requested salary
  salary: z.string().nullish(),
  //Agreed salary
  name: z.string(),
  negotiatedSalary: z.string().nullish(),
  salaryCurrency: z.string(),
  leadExperience: z.boolean().nullish(),
  seniority: z.string(),
  englishLevel: z.string(),
  excitementRate: z.number().nullish(),
  declaredYoE: z.number().nullish(),
  degreeYear: z.number().nullish(),
  owner: z.object({ email: z.string() }).or(z.string()),
  reviewedBy: z.object({ email: z.string() }).or(z.string()),
  techInterviewer: z.object({ email: z.string() }).or(z.string()),
  author: z.object({ email: z.string() }).or(z.string()),
  authorName: z.string().nullable(),
  country: z.string().nullish(),
  countryShort: z.string().nullish(),
  timezone: z.string().nullish(),
  availabilityShort: z.string().nullish(),
  submitedVoiceNote: z.boolean().nullish(),
  status: z.number(),
  //Dtapplied and dt_created.
  created_at: z.string(),
  updated_at: z.string(),
  referredBy: z.string().nullish(),
  referredByName: z.string().nullable(),
  sourcedVia: z.string().nullish(),
  sourcedViaText: z.string().nullish(),
  foundVia: z.string().nullish(),
  utmMedium: z.string().nullish(),
  utmSource: z.string().nullish(),
  statusHistory: z.array(StatusHistorySchema).nullable(),
  reviewedAt: z.date().nullable(),
  statusShort: z.string(),
  brainsId: z.string().nullish(),
  skillLevels: z.array(ApplicationSkillLevelSchema),
  answersQty: z.number().nullish(),
  jobShortName: z.number().nullish(),
  nativeEnglish: z.boolean().nullish(),
  timezoneNumber: z.number().nullish(),
  ownerName: z.string().nullish(),
  cost: z.number().nullish(),
  salaryValue: z.number().nullish(),
  salaryText: z.string().nullish(),
  availability: z.string().nullish(),
  source: z.string().nullish(),
  assignments: z.array(ApplicationAssignmentSChema).nullish(),
  contractStatus: z.string().nullish(),
  autoRejected: z.boolean().nullable(),
  automatedEnglishScore: z
    .object({
      pronunciation: z.number(),
      fluency: z.number(),
      grammar: z.number(),
      coherence: z.number(),
      vocab: z.number(),
      overall: z.number(),
    })
    .nullish(),
  automatedEnglishLevel: z.number().nullish(),
  usdSalary: z.number().nullish(),
  contractType: z.nativeEnum(ExpectedContractType).nullish(),
});
export type ApplicationMongo = z.infer<typeof ApplicationMongoSchema>;
