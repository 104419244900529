import { z } from 'zod';
import { Assignment, AssignmentSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const AssignmentGetParamsSchema = AssignmentSchema.pick({
  assignment_id: true,
});
export type AssignmentGetParams = z.infer<typeof AssignmentGetParamsSchema>;

export type AssignmentGetResponse = Assignment | null;

export type AssignmentGetEndpoint = Endpoint<
  Method.GET,
  `/api/v2//assignments-new/${AssignmentGetParams['assignment_id']}`,
  AssignmentGetResponse,
  AssignmentGetParams,
  undefined,
  undefined
>;
