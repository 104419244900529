import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const PlanOppDptCostDetailItemSchema = z.object({
  person_id: z.string(),
  name: z.string(),
  ymonth: z.number(),
  yyear: z.number(),
  plan_category: z.string(),
  plan_section: z.string(),
  department_id: z.string(),
  department: z.string(),
  confidence_level: z.string(),
  islead: z.boolean(),
  amount: z.number(),
});
export type PlanOppDptCostDetailItem = z.infer<typeof PlanOppDptCostDetailItemSchema>;

export const PlanOppDptCostDetailFiltersSchema = z.object({
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PlanOppDptCostDetailFilters = z.infer<typeof PlanOppDptCostDetailFiltersSchema>;

export const PlanOppDptCostDetailQuerySchema = z.object({
  filters: PlanOppDptCostDetailFiltersSchema.optional(),
});
export type PlanOppDptCostDetailQuery = z.infer<typeof PlanOppDptCostDetailQuerySchema>;

export type PlanOppDptCostDetailResponse = PlanOppDptCostDetailItem[];

export type PlanOppDptCostDetailEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/opp-dpt-cost-detail',
  PlanOppDptCostDetailResponse,
  undefined,
  PlanOppDptCostDetailQuery,
  undefined
>;
