import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TJobCategory } from '../../jobs/table-schemas';

export const JobCategoryMoveSchema = z.object({
  parent_category_id: z.number(),
  category_ids: z.array(z.number()),
});

export type JobCategoryMove = z.infer<typeof JobCategoryMoveSchema>;

export type MoveJobCategoryParams = {};

export type MoveJobCategoryQuery = {};

export type MoveJobCategoryRequest = z.infer<typeof JobCategoryMoveSchema>;

export type MoveJobCategoryResponse = TJobCategory;

export type MoveJobCategoryEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql/job-categories`,
  MoveJobCategoryResponse,
  MoveJobCategoryParams,
  MoveJobCategoryQuery,
  MoveJobCategoryRequest
>;
