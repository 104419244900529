import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { PersonSchema } from '../people';

export const AssignmentSchema = z.object({
  assignment_id: z.string(),
  project_id: z.string(),
  position_id: z.string(),
  person_id: z.string(),
  dtsigned: dateString.nullable(),
  dtstart: dateString,
  dtend: dateString,
  dtcreate: dateString,
  dtupdate: dateString.nullable(),
  notes: z.string(),
  category: z.string().nullable(),
  isflexible: z.boolean(),
  enddatecertainty: z.string().nullable(),
  costalloc: z.string(),
});
export type Assignment = z.infer<typeof AssignmentSchema>;

export const AssignmentExtendedSchema = AssignmentSchema.merge(
  PersonSchema.pick({
    dthired: true,
    dtterminated: true,
    name: true,
    gravatar_url: true,
    email: true,
  }),
).extend({
  project_name: z.string(),
  position_name: z.string(),
  islead: z.boolean(),
});
export type AssignmentExtended = z.infer<typeof AssignmentExtendedSchema>;

export type AssignmentByPerson = {
  age: number;
  category: string | undefined;
  endDate: string | undefined;
  id: string;
  _id: string;
  position: string;
  project: {
    _id: string;
    client: {
      _id: string;
      name: string;
    };
    startDate: string;
    endDate: string;
    name: string;
    isSigned: boolean;
  };
};

export enum MongoEndDateCertainty {
  'WillEnd' = 'WillEnd',
  'MayEnd' = 'MayEnd',
  'Ongoing' = 'Ongoing',
}

export enum MongoAssignmentCategory {
  'Standard' = 'Standard',
  'Tech Lead' = 'Tech Lead',
  'Vacation Coverage' = 'Vacation Coverage',
  'Extra Help' = 'Extra Help',
  'Flexible' = 'Flexible',
  'Quality Assurance' = 'Quality Assurance',
  'Product Manager' = 'Product Manager',
  'Product Extra Help' = 'Product Extra Help',
}

export const AssignmentMongoSchema = z.object({
  _id: z.string(),
  person: z.string(),
  position: z.string(),
  project: z.string(),
  client: z.string(),
  startDate: dateString,
  endDate: dateString,
  endDateCertainty: z.nativeEnum(MongoEndDateCertainty).nullable(),
  category: z.nativeEnum(MongoAssignmentCategory),
  assignToCentrifyRole: z.boolean().nullish(),
  isFlexible: z.boolean().nullish(),
  hoursPerMonth: z.number().min(0).default(160),
  notes: z.string().nullish(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
});

export type AssignmentMongo = z.infer<typeof AssignmentMongoSchema>;

export const ActiveAssignmentsMarginSummarySchema = z.object({
  total_people_revenue: z.number(),
  total_people_cost: z.number(),
  total_people: z.number(),
  total_positions: z.number(),
  total_assignments: z.number(),
  clients_summary: z.array(
    z.object({
      client_id: z.string(),
      client_name: z.string(),
    }),
  ),
  projects_summary: z.array(
    z.object({
      project_id: z.string(),
      project_name: z.string(),
    }),
  ),
  departments_summary: z.array(
    z.object({
      department_id: z.string(),
      department_name: z.string(),
    }),
  ),
});

export enum AssignmentOverlapType {
  'Person' = 'Person',
  'Position' = 'Position',
}

export type ActiveAssignmentsMarginSummary = z.infer<typeof ActiveAssignmentsMarginSummarySchema>;
export const AssignmentOverlapSchema = PersonSchema.pick({
  name: true,
  gravatar_url: true,
  person_id: true,
  email: true,
})
  .merge(
    AssignmentSchema.pick({
      project_id: true,
      position_id: true,
      dtstart: true,
      dtend: true,
    }),
  )
  .extend({
    type: z.nativeEnum(AssignmentOverlapType),
    project_name: z.string(),
    position_name: z.string(),
    islead: z.boolean(),
  });
export type AssignmentOverlap = z.infer<typeof AssignmentOverlapSchema>;
