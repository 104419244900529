import { z } from 'zod';
import { dateString } from '../../infrastructure';
import { PersonSchema } from '../people';

export enum StaffingCandidateVoteTypes {
  'Approved' = 'Approved',
  'Rejected' = 'Rejected',
  'Maybe' = 'Maybe',
}

export const StaffingCandidateVoteSchema = z.object({
  staffing_candidate_vote_id: z.number(),
  staffing_candidate_id: z.number(),
  person_id: z.string(),
  reason: z.string(),
  vote: z.nativeEnum(StaffingCandidateVoteTypes),
  dt_created: dateString,
  dt_updated: dateString,
});
export type StaffingCandidateVote = z.infer<typeof StaffingCandidateVoteSchema>;

export const StaffingCandidateVoteExtendedSchema = StaffingCandidateVoteSchema.merge(
  PersonSchema.pick({
    name: true,
    email: true,
    gravatar_url: true,
  }),
);
export type StaffingCandidateVoteExtended = z.infer<typeof StaffingCandidateVoteExtendedSchema>;
