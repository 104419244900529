import { z } from 'zod';
import { StaffingCandidateVoteSchema } from '../base';

export const StaffingCandidateVoteUpdateSchema = StaffingCandidateVoteSchema.pick({
  staffing_candidate_vote_id: true,
  reason: true,
  vote: true,
}).partial({
  reason: true,
  vote: true,
});
export type StaffingCandidateVoteUpdate = z.infer<typeof StaffingCandidateVoteUpdateSchema>;
