import { Endpoint, Method } from '../../../infrastructure';
import { SQLPosition, SQLPositionSchema } from '../base';
import { z } from 'zod';

export const PositionSetIsOpenToSalesRequestSchema = SQLPositionSchema.pick({
  is_open_to_sales: true,
});

export const PositionSetIsOpenToSalesParamsSchema = SQLPositionSchema.pick({
  position_id: true,
});

export type PositionSetIsOpenToSalesResponse = SQLPosition;

export type PositionSetIsOpenToSalesParams = z.infer<typeof PositionSetIsOpenToSalesParamsSchema>;

export type PositionSetIsOpenToSalesRequest = z.infer<typeof PositionSetIsOpenToSalesRequestSchema>;

export type PositionSetIsOpenToSalesEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions-new/${PositionSetIsOpenToSalesParams['position_id']}/set-is-open-to-sales`,
  PositionSetIsOpenToSalesResponse,
  PositionSetIsOpenToSalesParams,
  undefined,
  PositionSetIsOpenToSalesRequest
>;
