import { z } from 'zod';
import { PositionPostgresSchema } from '..';

export const PositionUpdateSchema = PositionPostgresSchema.pick({
  position_id: true,
  isstaffit: true,
  islead: true,
  name: true,
  dtplannedstart: true,
  dtplannedend: true,
  inv_pos_name: true,
  target_cost: true,
  hire_group: true,
  seniority: true,
  english_level: true,
  hard_date: true,
  dt_staffit: true,
  is_open_to_sales: true,
})
  .partial({
    isstaffit: true,
    islead: true,
    name: true,
    dtplannedstart: true,
    dtplannedend: true,
    inv_pos_name: true,
    target_cost: true,
    hire_group: true,
    seniority: true,
    english_level: true,
    hard_date: true,
    dt_staffit: true,
    is_open_to_sales: true,
  })
  .extend({
    is_sticky: z.boolean().nullish(),
    is_overlap_confirmed: z.boolean().nullish(),
  });

export type PositionUpdate = z.infer<typeof PositionUpdateSchema>;
