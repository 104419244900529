import { z } from 'zod';
import { Endpoint, FilterOperations, Method, dateString, makeSorter } from '../../infrastructure';
import { ListSQLPositionsSchema } from './listSQLPositions';
import { stringToBoolean } from '../../infrastructure/schema';

export const PositionListPageSchema = ListSQLPositionsSchema;
export type PositionListPage = z.infer<typeof PositionListPageSchema>;

//Filters
export const PositionListPageFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  dtplannedend: z
    .record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      dateString,
    )
    .optional(),
  dtplannedstart: z
    .record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      dateString,
    )
    .optional(),
  islead: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.boolean()).optional(),
  isstaffit: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.boolean()).optional(),
  is_assigned: z
    .record(z.enum([FilterOperations.EQUAL, FilterOperations.NOT_EQUAL]), z.coerce.boolean())
    .optional(),
  searchText: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
  includeArchived: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
});
export type PositionListPageFilters = z.infer<typeof PositionListPageFiltersSchema>;

export const PositionListPageSorterSchema = makeSorter(
  z.enum([
    'name',
    'seniority',
    'english_level',
    'target_cost',
    'dtplannedstart',
    'dtplannedend',
    'skills',
    'revenueamount',
    'hire_group',
    'asn_start',
    'asn_end',
  ]),
);
export type PositionListPageSorter = z.infer<typeof PositionListPageSorterSchema>;

//Query
export const PositionListPageQuerySchema = z.object({
  filters: PositionListPageFiltersSchema.optional(),
  sorter: PositionListPageSorterSchema.optional(),
});
export type PositionListPageQuery = z.infer<typeof PositionListPageQuerySchema>;

//Response
export type PositionListPageResponse = PositionListPage[];

//Endpoint
export type PositionListPageEndpoint = Endpoint<
  Method.GET,
  '/api/v2/positions-new/list-page',
  PositionListPageResponse,
  undefined,
  PositionListPageQuery,
  undefined
>;
