import { z } from 'zod';
import { dateString } from '../../infrastructure';
import { AppActionTypes } from '../app_action/base';

export enum StaffingCandidateStatus {
  'PT' = 'Potential Fit',
  'IN' = 'Interviewing',
  'FN' = 'Finalized',
  'HR' = 'Hired',
  'RJ' = 'Rejected',
}

export enum StaffingCandidateRating {
  'A' = 1,
  'B' = 2,
  'C' = 3,
  ' ' = 4,
}
export const RatingActionMapping = {
  1: AppActionTypes.CandidateRatingA,
  2: AppActionTypes.CandidateRatingB,
  3: AppActionTypes.CandidateRatingC,
  4: AppActionTypes.CandidateRatingRemove,
};

export const getRatingLetter = (rating: number) => {
  switch (rating) {
    case 1:
      return 'A';
    case 2:
      return 'B';
    case 3:
      return 'C';
    case 4:
      return ' ';
  }
};

export const StaffingCandidateSchema = z.object({
  staffing_candidate_id: z.number(),
  position_staffing_id: z.number(),
  application_id: z.string().nullable(),
  person_id: z.string().nullable(),
  staffing_recruiter_id: z.string(),
  status: z.nativeEnum(StaffingCandidateStatus),
  rating: z.number(),
  is_approved: z.boolean(),
  dt_created: z.date(),
  dt_updated: z.date(),
});

export type StaffingCandidate = z.infer<typeof StaffingCandidateSchema>;
