import { z } from 'zod';
import { TJobVideoQuestionSchema } from '../table-schemas';
import { Endpoint, Method } from '../../../infrastructure';

export const ListJobVideoQuestionsItemSchema = z.object({
  video_question_id: TJobVideoQuestionSchema.shape.video_question_id,
  mongo_id: TJobVideoQuestionSchema.shape.mongo_id,
  name: TJobVideoQuestionSchema.shape.name,
  video_filename: TJobVideoQuestionSchema.shape.video_filename,
  video_urls: z.object({
    mp4: z.string().nullish(),
    webm: z.string().nullish(),
  }),
});
export type ListJobVideoQuestionsItem = z.infer<typeof ListJobVideoQuestionsItemSchema>;

export const ListJobVideoQuestionsResponseSchema = z.object({
  video_questions: ListJobVideoQuestionsItemSchema.array(),
});
export type ListJobVideoQuestionsResponse = z.infer<typeof ListJobVideoQuestionsResponseSchema>;

export type ListJobVideoQuestionsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/job-video-questions',
  ListJobVideoQuestionsResponse,
  {},
  undefined,
  {}
>;
