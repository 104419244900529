import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { SQLPosition, SQLPositionSchema } from '../base';

export const PositionBulkTerminationRequestSchema = SQLPositionSchema.pick({
  dtplannedend: true,
}).extend({
  positions_id: z.array(z.string()),
  project_id: z.string().nullish() //Used for the FE.
});
export type PositionBulkTerminationRequest = z.infer<typeof PositionBulkTerminationRequestSchema>;

export type PositionBulkTerminationResponse = SQLPosition[];

export type PositionBulkTerminationEndpoint = Endpoint<
  Method.POST,
  `/api/v2/positions-new/bulk-termination`,
  PositionBulkTerminationResponse,
  undefined,
  undefined,
  PositionBulkTerminationRequest
>;
