import { z } from 'zod';
import { Assignment } from '../base';
import { Endpoint, Method } from '../../../infrastructure';
import { AssignmentFindSchema } from '../crud';

export const AssignmentListFiltersSchema = AssignmentFindSchema;
export type AssignmentListFilters = z.infer<typeof AssignmentListFiltersSchema>;

export const AssignmentListQuerySchema = z.object({
  filters: AssignmentListFiltersSchema.optional(),
});
export type AssignmentListQuery = z.infer<typeof AssignmentListQuerySchema>;

export type AssignmentListResponse = Assignment[];

export type AssignmentListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/assignments-new`,
  AssignmentListResponse,
  undefined,
  AssignmentListQuery,
  undefined
>;
