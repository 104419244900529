import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const CheckJobSuffixQuerySchema = z.object({
  job_family_id: z.string(),
  job_suffix: z.string(),
});
export type CheckJobSuffixQuery = z.infer<typeof CheckJobSuffixQuerySchema>;

export type CheckJobSuffixResponse = {
  exists: boolean;
};

export type CheckJobSuffixEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/job/check-suffix`,
  CheckJobSuffixResponse,
  undefined,
  CheckJobSuffixQuery,
  undefined
>;
