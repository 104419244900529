import { z } from 'zod';
import { Skill, SkillSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const SkillListFiltersSchema = SkillSchema.pick({}).partial();
export type SkillListFilters = z.infer<typeof SkillListFiltersSchema>;

export const SkillListQuerySchema = z.object({
  filters: SkillListFiltersSchema.optional(),
});
export type SkillListQuery = z.infer<typeof SkillListQuerySchema>;

export type SkillListResponse = Skill[];

export type SkillListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/skils`,
  SkillListResponse,
  undefined,
  SkillListQuery,
  undefined
>;
