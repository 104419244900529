import { TimeOffRequestSchema } from '../time_off_request/base';
import { z } from 'zod';

export enum TORCalendarEventsQueueType {
  'Personal' = 'Personal',
  'Project' = 'Project',
}

export enum TORCalendarEventsQueueOperation {
  'Create' = 'Create',
  'Delete' = 'Delete',
}

export const TORCalendarEventsQueueSchema = TimeOffRequestSchema.pick({
  time_off_request_id: true,
}).extend({
  type: z.nativeEnum(TORCalendarEventsQueueType),
  operation: z.nativeEnum(TORCalendarEventsQueueOperation),
  prj_google_calendar_id: z.number().nullish(),
});
export type TORCalendarEventsQueue = z.infer<typeof TORCalendarEventsQueueSchema>;
