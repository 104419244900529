import { lazy } from 'react';

const AsyncInterviewReport = lazy(() =>
  import('@containers/Reports/InterviewsReport/InterviewsReport'),
);
const AsyncNewDashboard = lazy(() => import('@containers/NewDashboard/NewDashboard'));

const AsyncApplication = lazy(() => import('@containers/Application/Application'));
const AsyncTechApplication = lazy(() => import('@src/containers/TechApplications/TechApplication'));
const AsyncApplications = lazy(() => import('@containers/Applications/Applications'));
const AsyncSalesApplication = lazy(() => import('@containers/SalesApplication/SalesApplication'));
const AsyncSalesPositions = lazy(() => import('@containers/SalesPositions/SalesPositions'));
const AsyncSalesPositionCandidate = lazy(() =>
  import('@containers/SalesPositionCandidate/SalesPositionCandidate'),
);

const AsyncJobForm = lazy(() => import('@containers/Job/JobForm'));
const AsyncJobs = lazy(() => import('@containers/Jobs'));

const AsyncJobHierarchy = lazy(() => import('@containers/JobHierarchy'));
const AsyncJobCategories = lazy(() => import('@containers/JobCategory'));
const AsyncJobPriorities = lazy(() => import('@containers/JobPriorities'));
const AsyncJobFamily = lazy(() => import('@containers/JobFamily'));

const AsyncNewApplicant = lazy(() => import('@containers/NewApplicant/NewApplicant'));

const AsyncMessages = lazy(() => import('@containers/Messages/Messages'));

const AsyncReports = lazy(() => import('@containers/Reports/index'));
const AsyncSalesReports = lazy(() => import('@containers/SalesReports/index'));

const AsyncActionsReport = lazy(() => import('@containers/Reports/ActionsReport'));
const AsyncStatusesReport = lazy(() => import('@containers/Reports/StatusesReport'));

const AsyncRipeReport = lazy(() => import('@containers/Reports/OnDeckReport/OnDeckReport'));
const AsyncOnDeck = lazy(() => import('@containers/Reports/OnDeck'));

const AsyncFocus = lazy(() => import('@containers/Reports/Focus/index'));
const AsyncFocusReport = lazy(() => import('@containers/Reports/FocusReport/FocusReport'));

const AsyncCriteriaAssessmentReport = lazy(() =>
  import('@containers/Reports/CriteriaAssessmentsReport'),
);

const AsyncCandidacyReport = lazy(() => import('@src/containers/CandidacyReport'));
const AsyncStaffingReviewReport = lazy(() => import('@src/containers/SalesReports/Staffing/index'));

const AsyncCandidacyDetails = lazy(() => import('@src/containers/CandidacyDetails'));
const AsyncPositionPage = lazy(() => import('@src/containers/PositionPage'));

const AsyncDashboard = lazy(() => import('@containers/Dashboard/Dashboard'));

const AsyncLateSatesReport = lazy(() => import('@containers/Reports/LateStageReport/Index'));
const AsyncReferralsReport = lazy(() => import('@containers/Reports/ReferralsReport/Index'));
const AsyncBulkEmailReport = lazy(() => import('@containers/Reports/BulkEmailsReport/Index'));

const AsyncQuestionsList = lazy(() => import('@containers/Questions/QuestionsList'));

const AsyncAddQuestionForm = lazy(() => import('@containers/Questions/AddQuestionForm'));

const AsyncEditQuestionForm = lazy(() => import('@containers/Questions/EditQuestionForm'));

const AsyncSourcesList = lazy(() => import('@containers/Sources/SourcesList'));

const AsyncMediumsList = lazy(() => import('@containers/Mediums/MediumsList'));

const AsyncRejectionReasonsList = lazy(() =>
  import('@containers/RejectionReasons/RejectionReasonsList'),
);

const AsyncTestReminderReport = lazy(() => import('@containers/Reports/TestReminderReport'));

const AsyncInterviewRequestedUnscheduled = lazy(() =>
  import('@containers/Reports/Interviews/RequestedUnscheduled'),
);

const AsyncInterviewHappenedScheduled = lazy(() =>
  import('@containers/Reports/Interviews/HappenedScheduled'),
);

const AsyncScheduledEmails = lazy(() => import('@containers/Reports/ScheduledEmails'));

const AsyncScorecardQuestions = lazy(() => import('@containers/ScorecardQuestions'));

const AsyncScorecard = lazy(() => import('@containers/Scorecard'));

const AsyncScorecardReview = lazy(() => import('@containers/Scorecard/ScorecardReview'));

const AsyncAccount = lazy(() => import('@containers/Account'));
const AsyncInboundEmailErrors = lazy(() => import('@containers/Reports/InboundEmailErrors'));
const AsyncRejectionReasonReport = lazy(() => import('@containers/Reports/RejectionReasonsReport'));
const AsyncInterviewRequestReport = lazy(() =>
  import('@containers/Reports/InterviewRequestReport'),
);

const AsyncCalendlyUsersList = lazy(() => import('@containers/CalendlyUsers/CalendlyUsersList'));
const AsyncCalendlyUsersNew = lazy(() => import('@containers/CalendlyUsers/CalendlyUsersNew'));
const AsyncCalendlyUsersUpdate = lazy(() =>
  import('@containers/CalendlyUsers/CalendlyUsersUpdate'),
);
const AsyncCalendlyUserTypes = lazy(() =>
  import('@containers/CalendlyUserTypes/CalendlyUserTypes'),
);

// NEW ASSIGNMENT TRACING HISTORY
const AsyncAssignmentsTracingHistoryReport = lazy(() =>
  import('@containers/Reports/AssignmentsTracingReport/AssignmentTracingReport'),
);
const AsyncAssignmentsTracingHistoryReportDetails = lazy(() =>
  import('@containers/Reports/AssignmentsTracingReport/AssignmentTracingReportDetails'),
);

const AsyncUnresponsiveApplicationsReport = lazy(() =>
  import('@containers/Reports/UnresponsiveApplicationReport/UnresponsiveApplicationReport'),
);

const AsyncTableauReport = lazy(() => import('@containers/Reports/TableauReport/TableauReport'));

const AsyncRoles = lazy(() => import('@containers/Roles/Roles'));
const AsyncUtmParams = lazy(() => import('@containers/UtmParams/UtmParams'));
const AsyncRejectedCountries = lazy(() => import('@containers/RejectedCountries'));
const AsyncCriteriaAssessments = lazy(() => import('@containers/CriteriaAssessments'));

export const adminRoutes = [
  {
    path: '/admin/dashboard/old',
    children: <AsyncInterviewReport />,
    exact: true,
  },
  {
    path: '/admin/dashboard',
    children: <AsyncNewDashboard />,
    exact: true,
  },
  {
    path: '/admin/candidates',
    children: <AsyncApplications />,
    exact: true,
  },
  {
    path: '/admin/application/:applicationId',
    children: <AsyncApplication />,
    exact: true,
  },

  {
    path: '/admin/account',
    children: <AsyncAccount />,
    exact: true,
  },
  {
    path: '/admin/application/:applicationId/scorecard/:scid',
    children: <AsyncScorecardReview />,
    exact: true,
  },
  {
    path: '/admin/application/:applicationId/scorecard',
    children: <AsyncScorecard />,
  },
  {
    path: '/admin/jobs',
    children: <AsyncJobs />,
    exact: true,
  },
  {
    path: '/admin/job/:type',
    children: <AsyncJobForm />,
  },
  {
    path: '/admin/job-hierarchy',
    children: <AsyncJobHierarchy />,
    exact: true,
  },
  {
    path: '/admin/job-categories',
    children: <AsyncJobCategories />,
    exact: true,
  },
  {
    path: '/admin/job-priorities',
    children: <AsyncJobPriorities />,
    exact: true,
  },
  {
    path: '/admin/job-family',
    children: <AsyncJobFamily />,
    exact: true,
  },
  {
    path: '/admin/refer',
    children: <AsyncNewApplicant />,
    exact: true,
  },
  {
    path: '/admin/messages',
    children: <AsyncMessages />,
    exact: true,
  },
  {
    path: '/admin/reports/index',
    children: <AsyncReports />,
    exact: true,
  },
  {
    path: '/admin/reports/sql-assignments',
    children: <AsyncCandidacyReport />,
    exact: true,
  },
  {
    path: '/admin/reports/assignments/:leadId',
    children: <AsyncCandidacyDetails />,
    exact: true,
  },
  {
    path: '/sales/reports/staffing/:projectId',
    children: <AsyncStaffingReviewReport />,
    exact: true,
  },
  {
    path: '/admin/position/:positionId',
    children: <AsyncPositionPage />,
    exact: true,
  },
  {
    path: '/admin/reports/old-on-deck',
    children: <AsyncRipeReport />,
    exact: true,
  },
  {
    path: '/admin/reports/on-deck',
    children: <AsyncOnDeck />,
    exact: true,
  },
  {
    path: '/admin/reports/focus',
    children: <AsyncFocus />,
    exact: true,
  },
  {
    path: '/admin/reports/old-focus',
    children: <AsyncFocusReport />,
    exact: true,
  },
  {
    path: '/admin/reports/inbound-email-errors',
    children: <AsyncInboundEmailErrors />,
    exact: true,
  },
  {
    path: '/admin/reports/late-stages',
    children: <AsyncLateSatesReport />,
    exact: true,
  },
  {
    path: '/admin/reports/referrals',
    children: <AsyncReferralsReport />,
    exact: true,
  },
  {
    path: '/admin/reports/actions',
    children: <AsyncActionsReport />,
    exact: true,
  },
  {
    path: '/admin/reports/bulk-emails',
    children: <AsyncBulkEmailReport />,
    exact: true,
  },
  {
    path: '/admin/reports/statuses-report',
    children: <AsyncStatusesReport />,
    exact: true,
  },
  {
    path: '/admin/reports/criteria-assessment-report',
    children: <AsyncCriteriaAssessmentReport />,
    exact: true,
  },

  {
    path: '/admin/reports/interviews',
    children: <AsyncInterviewReport />,
    exact: true,
  },
  {
    path: '/admin/reports/interview-requested-never-scheduled',
    children: <AsyncInterviewRequestedUnscheduled />,
    exact: true,
  },
  {
    path: '/admin/reports/interview-happened-still-scheduled',
    children: <AsyncInterviewHappenedScheduled />,
    exact: true,
  },
  {
    path: '/admin/reports/scheduled-emails',
    children: <AsyncScheduledEmails />,
    exact: true,
  },
  {
    path: '/admin/reports/test-reminder-campaign',
    children: <AsyncTestReminderReport />,
    exact: true,
  },
  {
    path: '/admin/reports/hiredapplicants',
    children: <AsyncDashboard />,
  },
  {
    path: '/admin/reports/rejection-report',
    children: <AsyncRejectionReasonReport />,
  },
  {
    path: '/admin/reports/interview-request-report',
    children: <AsyncInterviewRequestReport />,
  },
  {
    path: '/admin/scorecard-questions',
    children: <AsyncScorecardQuestions />,
    exact: true,
  },

  {
    path: '/admin/questions',
    children: <AsyncQuestionsList />,
    exact: true,
  },
  {
    path: '/admin/questions/new',
    children: <AsyncAddQuestionForm />,
    exact: true,
  },
  {
    path: '/admin/question/:id',
    children: <AsyncEditQuestionForm />,
    exact: true,
  },
  {
    path: '/admin/sources',
    children: <AsyncSourcesList />,
    exact: true,
  },
  {
    path: '/admin/mediums',
    children: <AsyncMediumsList />,
    exact: true,
  },
  {
    path: '/admin/rejection-reasons',
    children: <AsyncRejectionReasonsList />,
    exact: true,
  },

  {
    path: '/admin/calendly-users',
    children: <AsyncCalendlyUsersList />,
    exact: true,
  },
  {
    path: '/admin/calendly-users/new',
    children: <AsyncCalendlyUsersNew />,
    exact: true,
  },
  {
    path: '/admin/calendly-users/:id',
    children: <AsyncCalendlyUsersUpdate />,
    exact: true,
  },
  {
    path: '/admin/calendly-user-types',
    children: <AsyncCalendlyUserTypes />,
    exact: true,
  },

  {
    path: '/admin/roles',
    children: <AsyncRoles />,
    exact: true,
  },
  {
    path: '/admin/utm-params',
    children: <AsyncUtmParams />,
    exact: true,
  },
  {
    path: '/admin/rejected-countries',
    children: <AsyncRejectedCountries />,
    exact: true,
  },
  {
    path: '/admin/criteria-assessments',
    children: <AsyncCriteriaAssessments />,
    exact: true,
  },
  {
    path: '/admin/reports/assignment-tracing-report',
    children: <AsyncAssignmentsTracingHistoryReport />,
    exact: true,
  },
  {
    path: '/admin/reports/assignment-tracing-report/:id',
    children: <AsyncAssignmentsTracingHistoryReportDetails />,
    exact: true,
  },
  {
    path: '/admin/reports/unresponsive-applications',
    children: <AsyncUnresponsiveApplicationsReport />,
    exact: true,
  },
  {
    path: '/admin/reports/tableau',
    children: <AsyncTableauReport />,
  },
];

export const techRoutes = [
  {
    path: '/tech/dashboard',
    children: <AsyncInterviewReport />,
    exact: true,
  },
  {
    path: '/tech/account',
    children: <AsyncAccount />,
    exact: true,
  },
  {
    path: '/tech/application/:applicationId',
    children: <AsyncTechApplication />,
    exact: true,
  },
];

export const salesRoutes = [
  {
    path: '/sales/reports',
    children: <AsyncSalesReports />,
    exact: true,
  },
  {
    path: '/sales/reports/staffing/:projectId',
    children: <AsyncStaffingReviewReport />,
    exact: true,
  },
  {
    path: '/sales/account',
    children: <AsyncAccount />,
    exact: true,
  },
  {
    path: '/sales/application/:applicationId',
    children: <AsyncSalesApplication />,
    exact: true,
  },
  {
    path: '/sales/positions',
    children: <AsyncSalesPositions />,
    exact: true,
  },
  {
    path: '/sales/positions/:positionId',
    children: <AsyncSalesPositionCandidate />,
    exact: true,
  },
];

export const routerNavigationData = [...adminRoutes, ...techRoutes, ...salesRoutes].map(
  (route) => ({
    href: route.path,
  }),
);
