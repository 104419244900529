import { Endpoint, Method } from '../../../infrastructure';
import { StaffingCandidateVoteDeleteSchema } from '../crud';
import { z } from 'zod';

export const StaffingCandidateVoteDeleteParamsSchema = StaffingCandidateVoteDeleteSchema.pick({
  staffing_candidate_vote_id: true,
});
export type StaffingCandidateVoteDeleteParams = z.infer<
  typeof StaffingCandidateVoteDeleteParamsSchema
>;

export type StaffingCandidateVoteDeleteResponse = {};

export type StaffingCandidateVoteDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/staffing-candidate-votes/${StaffingCandidateVoteDeleteParams['staffing_candidate_vote_id']}`,
  StaffingCandidateVoteDeleteResponse,
  StaffingCandidateVoteDeleteParams,
  undefined,
  undefined
>;
