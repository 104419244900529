import { RevenueRateSchema } from '../../revenue-rate';
import { PositionUpdateSchema } from '../crud';
import { SQLPosition, SQLPositionSchema } from '../base';
import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const PositionUpdateRequestSchema = PositionUpdateSchema.pick({
  isstaffit: true,
  islead: true,
  name: true,
  dtplannedstart: true,
  dtplannedend: true,
  inv_pos_name: true,
  target_cost: true,
  hire_group: true,
  seniority: true,
  english_level: true,
  hard_date: true,
  dt_staffit: true,
  is_overlap_confirmed: true,
  is_sticky: true,
  is_open_to_sales: true,
})
  .merge(
    RevenueRateSchema.pick({
      revenueamount: true,
    }).partial(),
  )
  .extend({
    skills_id: z.number().array().nullish(),
    use_skills_computed_name: z.boolean().nullish(),
  });
export type PositionUpdateRequest = z.infer<typeof PositionUpdateRequestSchema>;

export const PositionUpdateParamsSchema = SQLPositionSchema.pick({
  position_id: true,
});
export type PositionUpdateParams = z.infer<typeof PositionUpdateParamsSchema>;

export type PositionUpdateResponse = SQLPosition;

export type PositionUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions-new/${PositionUpdateParams['position_id']}`,
  PositionUpdateResponse,
  PositionUpdateParams,
  undefined,
  PositionUpdateRequest
>;
