import { z } from 'zod';
import { SQLPositionExtended, PositionPostgresSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const PositionListFiltersSchema = PositionPostgresSchema.pick({
  project_id: true,
  islead: true,
  isstaffit: true,
}).partial();
export type PositionListFilters = z.infer<typeof PositionListFiltersSchema>;

export const PositionListQuerySchema = z.object({
  filters: PositionListFiltersSchema.optional(),
});
export type PositionListQuery = z.infer<typeof PositionListQuerySchema>;

export type PositionListResponse = SQLPositionExtended[];

export type PositionListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/positions-new`,
  PositionListResponse,
  undefined,
  PositionListQuery,
  undefined
>;
