import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const PlanOppUnassignedCostDetailItemSchema = z.object({
  project_id: z.string(),
  name: z.string(),
  ymonth: z.number(),
  yyear: z.number(),
  plan_category: z.string(),
  plan_section: z.string(),
  confidence_level: z.string(),
  amount: z.number(),
  num_positions: z.number(),
  islead: z.number(),
});
export type PlanOppUnassignedCostDetailItem = z.infer<typeof PlanOppUnassignedCostDetailItemSchema>;

export const PlanOppUnassignedCostDetailFiltersSchema = z.object({
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});
export type PlanOppUnassignedCostDetailFilters = z.infer<
  typeof PlanOppUnassignedCostDetailFiltersSchema
>;

export const PlanOppUnassignedCostDetailQuerySchema = z.object({
  filters: PlanOppUnassignedCostDetailFiltersSchema.optional(),
});
export type PlanOppUnassignedCostDetailQuery = z.infer<
  typeof PlanOppUnassignedCostDetailQuerySchema
>;

export type PlanOppUnassignedCostDetailResponse = PlanOppUnassignedCostDetailItem[];

export type PlanOppUnassignedCostDetailEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/opp-unassigned-cost-detail',
  PlanOppUnassignedCostDetailResponse,
  undefined,
  PlanOppUnassignedCostDetailQuery,
  undefined
>;
