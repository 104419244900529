import { PositionDeleteSchema } from '../crud';
import { SQLPosition, SQLPositionSchema } from '../base';
import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const PositionDeleteRequestSchema = PositionDeleteSchema.pick({
  deleted_at: true,
  deleted_by: true,
});
export type PositionDeleteRequest = z.infer<typeof PositionDeleteRequestSchema>;

export const PositionDeleteParamsSchema = SQLPositionSchema.pick({
  position_id: true,
});
export type PositionDeleteParams = z.infer<typeof PositionDeleteParamsSchema>;

export type PositionDeleteResponse = SQLPosition;

export type PositionDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/positions-new/${PositionDeleteParams['position_id']}`,
  PositionDeleteResponse,
  PositionDeleteParams,
  undefined,
  PositionDeleteRequest
>;
