import { AssignmentSchema } from '../base';
import { z } from 'zod';

export const AssignmentCreateSchema = AssignmentSchema.pick({
  project_id: true,
  position_id: true,
  person_id: true,
  dtstart: true,
  dtend: true,
  category: true,
  enddatecertainty: true,
  isflexible: true,
  notes: true,
}).extend({
  is_confirmed: z.boolean().nullish(),
  department_id: z.string().nullish(),
  user: z
    .object({
      person: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullish(),
});

export type AssignmentCreate = z.infer<typeof AssignmentCreateSchema>;
