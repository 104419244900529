import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const ListJobFamilyItemSchema = z.object({
  job_family_id: z.number(),
  title: z.string(),
});
export type ListJobFamilyItem = z.infer<typeof ListJobFamilyItemSchema>;

export type ListJobFamilyResponse = ListJobFamilyItem[];

export type ListJobFamilyEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/job-families',
  ListJobFamilyResponse,
  {},
  undefined,
  {}
>;
