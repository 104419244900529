import { z } from 'zod';
import { JobStatusSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const PatchJobStatusRequestSchema = z.object({
  status: JobStatusSchema,
});
export type PatchJobStatusRequest = z.infer<typeof PatchJobStatusRequestSchema>;

export type PatchJobStatusResponse = {};

export const PatchJobStatusParamsSchema = z.object({
  job_id: z.string(),
});
export type PatchJobStatusParams = z.infer<typeof PatchJobStatusParamsSchema>;

export type PatchJobStatusEndpoint = Endpoint<
  Method.PATCH,
  `/api/v2/sql/jobs/:job_id`,
  PatchJobStatusResponse,
  PatchJobStatusParams,
  undefined,
  PatchJobStatusRequest
>;
