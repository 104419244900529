import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { SQLPosition, SQLPositionSchema } from '../base';

export const PositionBulkStaffingRequestSchema = SQLPositionSchema.pick({
  isstaffit: true,
  hire_group: true,
  dt_staffit: true,
}).extend({
  positions_id: z.array(z.string()),
  project_id: z.string().nullish() //used in the front end
});
export type PositionBulkStaffingRequest = z.infer<typeof PositionBulkStaffingRequestSchema>;

export type PositionBulkStaffingResponse = SQLPosition[];

export type PositionBulkStaffingEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions-new/bulk-staffing`,
  PositionBulkStaffingResponse,
  undefined,
  undefined,
  PositionBulkStaffingRequest
>;
