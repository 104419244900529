import { z } from 'zod';
import { dateString } from '../../../infrastructure/schema';
import { Endpoint, Method } from '../../../infrastructure';

//Body
export const PositionReplaceCurrentAssignmentRequestSchema = z.object({
  previous_asn_end: dateString,
  new_asn_start: dateString,
  new_asn_end: dateString,
  new_asn_person: z.string(),
});
export type PositionReplaceCurrentAssignmentRequest = z.infer<
  typeof PositionReplaceCurrentAssignmentRequestSchema
>;

export const PositionReplaceCurrentAssignmentParamsSchema = z.object({
  position_id: z.string(),
});
export type PositionReplaceCurrentAssignmentParams = z.infer<
  typeof PositionReplaceCurrentAssignmentParamsSchema
>;

export type PositionReplaceCurrentAssignmentResponse = {};

export type PositionReplaceCurrentAssignmentEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions-new/${PositionReplaceCurrentAssignmentParams['position_id']}/replace-current-assignment`,
  PositionReplaceCurrentAssignmentResponse,
  PositionReplaceCurrentAssignmentParams,
  undefined,
  PositionReplaceCurrentAssignmentRequest
>;
