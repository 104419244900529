import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

import { TRecruiterFocus, TRecruiterMonthlyFocusSchema } from '../table-schema';

export const CreateRecruiterMonthlyFocusSchema = TRecruiterMonthlyFocusSchema.pick({
  recruiter_id: true,
}).extend({
  current_month_goal: z.number(),
  next_month_goal: z.number(),
});

export type CreateRecruiterMonthlyFocusParams = {};

export type CreateRecruiterMonthlyFocusQuery = {};

export type CreateRecruiterMonthlyFocusRequest = z.infer<typeof CreateRecruiterMonthlyFocusSchema>;

export type CreateRecruiterMonthlyFocusResponse = Readonly<TRecruiterFocus[]>;

export type CreateRecruiterMonthlyFocusEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql/recruiter-focus/monthly`,
  CreateRecruiterMonthlyFocusResponse,
  CreateRecruiterMonthlyFocusParams,
  CreateRecruiterMonthlyFocusQuery,
  CreateRecruiterMonthlyFocusRequest
>;
