import { z } from 'zod';
import { PositionConflictSchema, PositionPostgresSchema, SQLPositionSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';
import { AssignmentOverlapSchema } from '../../assignment';

export const PositionConflictsAndOverlapsRequestSchema = PositionPostgresSchema.pick({
  dtplannedstart: true,
  dtplannedend: true,
})
  .partial()
  .extend({
    is_sticky: z.boolean().nullish(),
  });
export type PositionConflictsAndOverlapsRequest = z.infer<
  typeof PositionConflictsAndOverlapsRequestSchema
>;

export const PositionConflictsAndOverlapsParamsSchema = SQLPositionSchema.pick({
  position_id: true,
});
export type PositionConflictsAndOverlapsParams = z.infer<
  typeof PositionConflictsAndOverlapsParamsSchema
>;

export const PositionConflictsAndOverlapsResponseSchema = z.object({
  conflicts: z.array(PositionConflictSchema),
  overlaps: z.array(AssignmentOverlapSchema),
});

export type PositionConflictsAndOverlapsResponse = z.infer<
  typeof PositionConflictsAndOverlapsResponseSchema
>;

export type PositionConflictsAndOverlapsEndpoint = Endpoint<
  Method.POST,
  `/api/v2/positions-new/${PositionConflictsAndOverlapsParams['position_id']}/conflicts-overlaps`,
  PositionConflictsAndOverlapsResponse,
  PositionConflictsAndOverlapsParams,
  undefined,
  PositionConflictsAndOverlapsRequest
>;
