import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { Table as ATable, Skeleton, Empty, Button, Row, Grid, Col } from 'antd';
import type { TableColumnType, TableProps } from 'antd';
import isEqual from 'lodash/isEqual';

import TableContext from '../../context/Context';
import { toShortDirection } from '../../utils/sorter';
import { useDebounce } from '../../hooks/useDebounce';
import { pageToSkipPagination, skipToPagePagination } from '../../utils/pagination';
import { PaginationQuery, SortDirection } from '@ct-internal/api';

import { ExpandableRow, ExpandableTable, ExpandableHeader } from './custom-components';
import ToggleColumns, { getToggleId } from './toggle-columns';
import { NewInfoTooltip } from '@ct-internal/ui';

import './index.less';

export type ColumnType<T> = TableColumnType<T> & {
  priority?: number;
  getColSpan?: (record: T) => number;
  children?: ColumnType<T>[];
};

interface ReportTableProps<T extends {}, C extends {}> {
  tableId?: string;
  title?: TableProps<T>['title'];
  rowKey?: TableProps<T>['rowKey'];
  data: T[];
  columns?: ColumnType<T>[];
  total?: number;
  isLoading?: boolean;
  enableVerticalView?: boolean;
  enableResponsiveColumns?: boolean;
  enableToggleColumns?: boolean;
  // To get key properly typed - using column dataIndex and sorter:true
  // ReportTable should use our own ColumnProps - this could be an improvement
  onSort?: (key: any, direction: SortDirection | undefined) => void;
  pagination?: PaginationQuery;
  onPaginate?: (pagination: PaginationQuery) => void;
  onFilter?: (filters: any) => void;
  context?: C;
  summary?: TableProps<T>['summary'];
  style?: TableProps<T>['style'];
  expandable?: TableProps<T>['expandable'];
  showHeader?: TableProps<T>['showHeader'];
  onRow?: TableProps<T>['onRow'];
  bordered?: TableProps<T>['bordered'];
  rowSelection?: TableProps<T>['rowSelection'];
  scroll?: TableProps<T>['scroll'];
  className?: TableProps<T>['className'];
  sticky?: TableProps<T>['sticky'];
  rowClassName?: TableProps<T>['rowClassName'];
  components?: TableProps<T>['components'];
}

const sortColumnsByPriority = <T extends {}>(a: ColumnType<T>, b: ColumnType<T>): number =>
  (a.priority || 1000) - (b.priority || 1000);

function textContent(elem?: any): string {
  if (!elem) {
    return '';
  }
  if (typeof elem === 'string') {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(textContent).join('');
  }
  return textContent(children);
}

// eslint-disable-next-line max-lines-per-function, complexity
const Table = <T extends {}, C extends {} = {}>({
  tableId,
  data,
  columns,
  total,
  isLoading,
  onSort,
  onPaginate,
  onFilter,
  context,
  pagination,
  summary,
  expandable,
  rowKey,
  showHeader,
  style,
  onRow,
  bordered = true,
  title,
  rowSelection,
  scroll,
  className,
  sticky,
  rowClassName,
  enableVerticalView,
  enableResponsiveColumns,
  enableToggleColumns,
  components,
}: ReportTableProps<T, C>) => {
  const screens = Grid.useBreakpoint();

  const tableRef = useRef(null);
  const rowKeyRef = useRef(rowKey);

  const applyOnCell = useCallback(
    (column: ColumnType<T>): ColumnType<T> => ({
      ...column,
      onCell: (record: T) => {
        const existingOnCell =
          typeof column.onCell === 'function' ? column.onCell(record) : column.onCell || {};

        return {
          ...existingOnCell,
          colSpan: column.getColSpan ? column.getColSpan(record) : undefined,
        };
      },
      children: column.children?.map(applyOnCell),
    }),
    [],
  );

  const mergedColumns = useMemo(() => {
    return columns?.map(applyOnCell);
  }, [columns, applyOnCell]);

  const [expandableRef, setExpandableRef] = useState(expandable);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [columnsToShow, setColumnsToShow] = useState(mergedColumns?.length || 0);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);

  const [toggledColumns, setToggledColumns] = useState<ColumnType<T>[]>([]);

  const debouncedWrapperWidth = useDebounce(wrapperWidth, 500);
  const debouncedTableWidth = useDebounce(tableWidth, 500);

  useEffect(() => {
    if (mergedColumns?.length) {
      setColumnsToShow(mergedColumns?.length);
    }
  }, [mergedColumns?.length]);

  useEffect(() => {
    if (mergedColumns) {
      setToggledColumns((toggled) => (!toggled.length ? mergedColumns : toggled));
    }
  }, [mergedColumns]);

  useEffect(() => {
    if (expandableRef && !isEqual(expandableRef?.expandedRowKeys, expandable?.expandedRowKeys)) {
      setExpandableRef(expandable);
    }
  }, [expandableRef, expandable]);

  const isFirstLoading = isLoading && data === undefined;

  const paginationConfig =
    pagination?.limit === Infinity
      ? false
      : {
          ...skipToPagePagination(pagination?.skip, pagination?.limit),
          hideOnSinglePage: pagination?.hideOnSinglePage || false,
          disabled: pagination?.disabled || false,
          total,
        };

  const handleTableChange = (
    pagination: { current?: number; pageSize?: number },
    filters: any,
    sort: { field?: any; order?: 'ascend' | 'descend' | null; columnKey?: any } | any[],
    extra: { action: 'sort' | 'paginate' | 'filter' },
  ) => {
    const { action } = extra;
    const { current, pageSize } = pagination;
    if (action === 'sort' && onSort && !Array.isArray(sort)) {
      onSort(
        Array.isArray(sort.field) ? sort.field.join('.') : sort.field || sort.columnKey,
        toShortDirection(sort.order),
      );
    }
    if (action === 'paginate' && onPaginate && pageSize) {
      onPaginate(pageToSkipPagination(current || 0, pageSize));
    }
    if (action === 'filter' && onFilter) {
      onFilter(filters);
    }
  };

  const mergedStyle = {
    ...Table.defaultProps.style,
    ...style,
    width: `100% ${enableVerticalView && !showAllColumns ? '!important' : ''}`,
  };

  const filteredColumns = useMemo(() => {
    if (mergedColumns) {
      const priorityColumns = mergedColumns
        .filter((column) => column.priority)
        .sort(sortColumnsByPriority);

      if (columnsToShow > 0 && columnsToShow <= priorityColumns.length) {
        return priorityColumns
          .slice(0, columnsToShow)
          .sort((a, b) => mergedColumns.indexOf(a) - mergedColumns.indexOf(b));
      }

      let nAllowedColumns = columnsToShow - priorityColumns.length;
      return mergedColumns.filter((column) => {
        if (column.priority) {
          return true;
        }

        if (nAllowedColumns > 0) {
          nAllowedColumns--;
          return true;
        }

        return false;
      });
    }
  }, [mergedColumns, columnsToShow]);

  const tableComponents = useMemo(
    () => ({
      table: (props: any) => <ExpandableTable {...props} showAllColumns={showAllColumns} />,
      header: {
        row: ExpandableHeader,
      },
      body: {
        row: (props: any) => {
          return (
            <ExpandableRow<T>
              {...props}
              expandable={expandableRef}
              dataSource={data}
              rowKey={rowKeyRef.current}
              columns={mergedColumns?.filter((col) => !filteredColumns?.includes(col)) ?? []}
            />
          );
        },
      },
    }),
    [mergedColumns, data, expandableRef, filteredColumns, showAllColumns],
  );

  const mergedComponents = useMemo(() => {
    const defaultComponents =
      enableVerticalView &&
      columnsToShow !== mergedColumns?.length &&
      (!enableToggleColumns || !screens.md)
        ? tableComponents
        : undefined;

    return {
      ...defaultComponents,
      ...components,
    };
  }, [
    enableVerticalView,
    columnsToShow,
    mergedColumns?.length,
    enableToggleColumns,
    screens.md,
    tableComponents,
    components,
  ]);

  useEffect(() => {
    if (tableRef.current && mergedColumns) {
      const container = tableRef.current as HTMLDivElement;
      const content = container.children[0];
      const table = content.getElementsByTagName('table')[0];

      let wrapperWidth = debouncedWrapperWidth;
      const tableWidth = debouncedTableWidth;

      if (wrapperWidth < tableWidth && !showAllColumns && !isLoading) {
        const headers = Array.from(table.getElementsByTagName('th'));
        let columnCounter = 0;

        if (enableVerticalView) {
          wrapperWidth -= screens.xs ? 24 : 37;
        }
        if (rowSelection) {
          wrapperWidth -= 45;
        }

        [...mergedColumns].sort(sortColumnsByPriority).forEach((column) => {
          const title = textContent(
            typeof column.title === 'function' ? column.title({}) : column.title,
          );

          const header = headers.find((h) => h.ariaLabel === title || h.textContent === title);

          wrapperWidth -= header?.offsetWidth || 0;

          if (wrapperWidth >= 0) {
            columnCounter += 1;
          }
        });

        setColumnsToShow(columnCounter);
      }
    }
  }, [
    mergedColumns,
    debouncedWrapperWidth,
    debouncedTableWidth,
    enableVerticalView,
    screens.xs,
    showAllColumns,
    rowSelection,
    isLoading,
  ]);

  useEffect(() => {
    if (tableRef.current && mergedColumns) {
      const container = tableRef.current as HTMLDivElement;
      const wrapper = container.children[0];
      const table = wrapper.getElementsByTagName('table')[0];

      const resizedTable = () => {
        const table = container.children[0].getElementsByTagName('table')[0];

        const wrapperWidth = container.offsetWidth;
        const tableWidth = table.offsetWidth;

        if (wrapperWidth < tableWidth && debouncedTableWidth !== tableWidth) {
          setTableWidth(table.offsetWidth);
        }
        if (debouncedWrapperWidth !== wrapperWidth) {
          setWrapperWidth(container.offsetWidth);
          setColumnsToShow(mergedColumns.length);
        }
      };

      const observer = new ResizeObserver(resizedTable);
      observer.observe(wrapper);
      observer.observe(table);

      return () => {
        observer.unobserve(wrapper);
        observer.unobserve(table);
        observer.disconnect();
      };
    }
  }, [mergedColumns, debouncedWrapperWidth, debouncedTableWidth]);

  const handleToggledColumns = useCallback(
    (value: string[]) => {
      setToggledColumns(
        mergedColumns?.filter((col, i) => value.includes(getToggleId(col, i))) || [],
      );
    },
    [mergedColumns],
  );

  const HeaderActions = (
    <Row justify={screens.xs ? 'start' : 'end'} gutter={8} wrap={false}>
      {enableToggleColumns && tableId && screens.md && (
        <Col>
          <ToggleColumns
            tableId={tableId}
            columns={mergedColumns}
            onChange={handleToggledColumns}
          />
        </Col>
      )}
      {enableResponsiveColumns &&
        columnsToShow !== mergedColumns?.length &&
        (!enableToggleColumns || !screens.md) && (
          <>
            <Col>
              <Button onClick={() => setShowAllColumns(!showAllColumns)}>
                {!showAllColumns ? 'Show all columns' : 'Show only priority columns'}
              </Button>
            </Col>
            <NewInfoTooltip>
              <div>
                <p>
                  This program has been modified to be more responsive to mobile devices or smaller
                  size windows.
                </p>
                <p>
                  For smaller screens, you will notice a new icon to the left of each row. Clicking
                  the icon will rotate the row 90 degrees and will present all the row's cells
                  vertically, with the headings as labels.
                </p>
                <br />
                <p>
                  We have assigned priorities to the report columns. As the available window width
                  gets smaller, the program will attempt to keep the priority columns visible and
                  hide the non-priority ones. This is done dynamically even on larger screens if you
                  change the window size. We are hoping to adjust the priority list based on your
                  input.
                </p>
              </div>
            </NewInfoTooltip>
          </>
        )}
    </Row>
  );

  let tableColumns = mergedColumns;
  if (enableToggleColumns && screens.md) {
    tableColumns = toggledColumns;
  } else if (enableResponsiveColumns && columnsToShow !== columns?.length && !showAllColumns) {
    tableColumns = filteredColumns;
  }

  return (
    <TableContext.Provider value={context}>
      <div>
        {!title && (enableToggleColumns || enableResponsiveColumns) ? (
          <div style={{ marginTop: '2rem' }}>{HeaderActions}</div>
        ) : null}
        <ATable
          ref={tableRef}
          title={
            title &&
            ((titleProps) => (
              <Row gutter={[8, 0]}>
                <Col flex={1}>{title(titleProps)}</Col>
                <Col>{HeaderActions}</Col>
              </Row>
            ))
          }
          className={className}
          columns={tableColumns as TableProps['columns']}
          style={mergedStyle}
          scroll={{ x: 'max-content', ...scroll }}
          bordered={bordered}
          size="small"
          loading={isLoading && !isFirstLoading}
          pagination={paginationConfig}
          rowKey={rowKey}
          onChange={handleTableChange}
          dataSource={data}
          summary={summary}
          locale={{
            emptyText: isFirstLoading ? (
              <Skeleton active={true} paragraph={{ rows: 12 }} />
            ) : (
              <Empty />
            ),
          }}
          rowSelection={rowSelection}
          expandable={expandable}
          showHeader={showHeader}
          onRow={onRow}
          sticky={sticky}
          rowClassName={rowClassName}
          components={mergedComponents}
        />
      </div>
    </TableContext.Provider>
  );
};

Table.defaultProps = {
  style: { marginTop: '2rem', width: '100%' },
  bordered: true,
};

export default Table;
