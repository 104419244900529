import { z } from 'zod';

import {
  Method,
  Endpoint,
  dateString,
  ReportsQuerySchema,
  FilterOperations,
} from '../../../infrastructure';

import { ListRecruiterFocusesReport } from '../base';

export const ListRecruiterFocusesFiltersSchema = z
  .object({
    dtstart: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), dateString),
  })
  .partial();

export type ListRecruiterFocusesFilters = z.infer<typeof ListRecruiterFocusesFiltersSchema>;

export const ListRecruiterFocusesQuerySchema = ReportsQuerySchema.extend({
  filters: ListRecruiterFocusesFiltersSchema,
  sorter: z.object({}),
}).partial();

export type ListRecruiterFocusesParams = {};

export type ListRecruiterFocusesQuery = z.infer<typeof ListRecruiterFocusesQuerySchema>;

export type ListRecruiterFocusesRequest = {};

export type ListRecruiterFocusesResponse = ListRecruiterFocusesReport;

export type ListRecruiterFocusesEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/recruiter-focus/report`,
  ListRecruiterFocusesResponse,
  ListRecruiterFocusesParams,
  ListRecruiterFocusesQuery,
  ListRecruiterFocusesRequest
>;
