import { z } from 'zod';
import { Endpoint, Method, dateString } from '../../../infrastructure';
import { SQLPosition } from '../base';
import { PositionUpdateSchema } from '../crud';

export enum DateType {
  'Start' = 'dtplannedstart',
  'End' = 'dtplannedend',
}
export const PositionBulkUpdateDateRequestSchema = PositionUpdateSchema.pick({
  is_overlap_confirmed: true,
  is_sticky: true,
}).extend({
  positions_id: z.array(z.string()),
  newDate: dateString,
  dateType: z.nativeEnum(DateType),
  project_id: z.string().nullish() //used in the front end
});
export type PositionBulkUpdateDateRequest = z.infer<typeof PositionBulkUpdateDateRequestSchema>;

export type PositionBulkUpdateDateResponse = SQLPosition[];

export type PositionBulkUpdateDateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/positions/bulk-update-date`,
  PositionBulkUpdateDateResponse,
  undefined,
  undefined,
  PositionBulkUpdateDateRequest
>;
