import { z } from 'zod';

export const JobStatuses = ['Active', 'Passive', 'Archived', 'Closed', 'Evergreen'] as const;
export type JobStatus = (typeof JobStatuses)[number];
export const JobStatusSchema = z.enum(JobStatuses);

export const OwnerExpandedSchema = z.object({
  person_id: z.string().nullable(),
  name: z.string().nullable(),
  email: z.string().nullable(),
  avatar_url: z.string().nullable(),
});

export type OwnerExpanded = z.infer<typeof OwnerExpandedSchema>;
