import dayjs, { Dayjs } from 'dayjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment, { Moment } from 'moment-timezone';
type MomentTuple = [Moment | null, Moment | null];
type DayjsTuple = [Dayjs | null, Dayjs | null];

export const getFormattedDayjsTuple = (
  tupleToFormat: MomentTuple,
  defaultTuple: DayjsTuple = [null, null],
): DayjsTuple => {
  if (tupleToFormat && tupleToFormat.length === 2) {
    const [firstDate, secondDate] = tupleToFormat;

    return [firstDate && dayjs(firstDate.toDate()), secondDate && dayjs(secondDate.toDate())];
  }

  return defaultTuple;
};

export const getFormattedDayjs = (
  toFormat: Moment | null,
  defaultValue: Dayjs | null = null,
): Dayjs | null => {
  if (toFormat) {
    return dayjs(toFormat.toDate());
  }

  return defaultValue;
};

export const getFormattedMomentTuple = (
  tupleToFormat: DayjsTuple,
  defaultTuple: MomentTuple = [null, null],
): MomentTuple => {
  if (tupleToFormat && tupleToFormat.length === 2) {
    const [firstDate, secondDate] = tupleToFormat;

    return [firstDate && moment(firstDate.toDate()), secondDate && moment(secondDate.toDate())];
  }

  return defaultTuple;
};

export const getFormattedMoment = (
  toFormat: Dayjs | null,
  defaultValue: Moment | null = null,
): Moment | null => {
  if (toFormat) {
    return moment(toFormat.toDate());
  }

  return defaultValue;
};
