import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { ContractTypes } from '../../contract-templates';
import { PersonInfoSchema } from '../schemas';
import { PtoSummarySchema } from '../../pto/base';

export const GetPersonInfoQuerySchema = z.object({
  email: z.string(),
});
export type GetPersonInfoQuery = z.infer<typeof GetPersonInfoQuerySchema>;

export const GetPersonInfoResponseSchema = PersonInfoSchema.omit({ coach_id: true }).extend({
  remaining_badges_use: z.number(),
  remaining_badges_give: z.number(),
  bio: z.string(),
  ptoSummary: PtoSummarySchema,
  pdfRequests: z
    .object({
      amount: z.number(),
      reason: z.string(),
      status: z.enum(['pending', 'approved', 'reimbursed']),
      receiptUrl: z.string(),
      year: z.number(),
      reimbursementDate: z.string().nullable(),
      payoutId: z.number(),
      created_at: z.number(),
    })
    .array(),
  activeTags: z.string().array().nullable(),
  slack_internal_id: z.string().nullable(),
  contract_type: z.enum(ContractTypes),
  coach: z
    .object({
      coach_id: z.string(),
      slack_id: z.string().nullable(),
      slack_internal_id: z.string().nullable(),
    })
    .optional(),
});
export type GetPersonInfoResponse = z.infer<typeof GetPersonInfoResponseSchema>;

export type GetPersonInfoEndpoint = Endpoint<
  Method.GET,
  '/api/coach-ai/person-info',
  GetPersonInfoResponse,
  {},
  GetPersonInfoQuery,
  {}
>;
