import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TJobCategorySchema, TJobCategory } from '../../jobs/table-schemas';

export const JobCategoryCreateSchema = TJobCategorySchema.pick({
  name: true,
}).extend({
  parent_category_id: z.number().optional(),
});

export type JobCategoryCreate = z.infer<typeof JobCategoryCreateSchema>;

export type CreateJobCategoryParams = {};

export type CreateJobCategoryQuery = {};

export type CreateJobCategoryRequest = z.infer<typeof JobCategoryCreateSchema>;

export type CreateJobCategoryResponse = Pick<TJobCategory, 'job_category_id'>;

export type CreateJobCategoryEndpoint = Endpoint<
  Method.POST,
  `/api/v2/sql/job-categories`,
  CreateJobCategoryResponse,
  CreateJobCategoryParams,
  CreateJobCategoryQuery,
  CreateJobCategoryRequest
>;
