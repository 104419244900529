import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { SQLPosition, SQLPositionSchema } from '../base';

export const PositionBulkTargetCostRequestSchema = SQLPositionSchema.pick({
  target_cost: true,
}).extend({
  positions_id: z.string().array(),
  project_id: z.string().nullish() // Used for the FE.
});

export type PositionBulkTargetCostRequest = z.infer<typeof PositionBulkTargetCostRequestSchema>;

export type PositionBulkTargetCostResponse = SQLPosition[];

export type PositionBulkTargetCostEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/positions-new/bulk-target-cost`,
  PositionBulkTargetCostResponse,
  undefined,
  undefined,
  PositionBulkTargetCostRequest
>;
