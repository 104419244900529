import { useCallback, useState } from 'react';
import { message } from 'antd';
import { BlobRequest, CSVRequest } from '@ct-internal/api';

const isBlobLike = (content: any): content is Blob => {
  return content && typeof content === 'object' && 'type' in content;
};

const downloadToFile = async (fileName: string, content: string | Blob, mimeType = 'text/csv') => {
  try {
    const element = document.createElement('a');
    const url =
      content instanceof Blob
        ? URL.createObjectURL(content)
        : `data:${mimeType};charset=utf-8,${encodeURIComponent(content)}`;

    element.setAttribute('href', url);
    element.setAttribute('download', fileName);

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    if (content instanceof Blob) {
      URL.revokeObjectURL(url);
    }
  } catch (e) {
    console.error('Download failed:', e);
    throw e;
  }
};

const useDownload = (
  load: CSVRequest | BlobRequest,
  filename: string,
  customDownload?: (filename: string, content: string) => void,
  customBlobDownload?: (filename: string, content: Blob) => void,
) => {
  const [isLoading, setIsLoading] = useState(false);

  const download = useCallback(async () => {
    setIsLoading(true);
    try {
      const content = await load();

      if (isBlobLike(content)) {
        const browserBlob = new Blob([content], { type: content.type });
        if (customBlobDownload) {
          customBlobDownload(filename, browserBlob);
        } else {
          await downloadToFile(filename, browserBlob);
        }
      } else {
        if (customDownload) {
          customDownload(filename, content as string);
        } else {
          await downloadToFile(filename, content as string);
        }
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : typeof err === 'string' ? err : 'Download failed';
      message.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [load, filename, customDownload, customBlobDownload]);

  return { download, isLoading };
};

export default useDownload;
