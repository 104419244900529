import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../infrastructure';
import { Lead } from './base';

export const OppSetSowRequestSchema = z.object({
  sow_link: z.string().nullable(),
  sow_dt_signed: dateString.nullable(),
});
export type OppSetSowRequest = z.infer<typeof OppSetSowRequestSchema>;

export const OppSetSowParamsSchema = z.object({
  project_id: z.string(),
});
export type OppSetSowParams = z.infer<typeof OppSetSowParamsSchema>;

export type OppSetSowResponse = Lead;

export type OppSetSowEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql-leads/${OppSetSowParams['project_id']}/set-sow`,
  OppSetSowResponse,
  OppSetSowParams,
  undefined,
  OppSetSowRequest
>;
