import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TJobLevel } from '../table-schemas';

export const ListJobLevelsQuerySchema = z
  .object({
    minLevel: z.coerce.number(),
    maxLevel: z.coerce.number(),
  })
  .partial();
export type ListJobLevelsQuery = z.infer<typeof ListJobLevelsQuerySchema>;

export type ListJobLevelsResponse = { levels: Readonly<TJobLevel[]> };

export type ListJobLevelsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/jobs/job-levels',
  ListJobLevelsResponse,
  {},
  undefined,
  {}
>;
