import { Endpoint, Method } from '../../../infrastructure';
import { AssignmentSchema } from '../base';
import { z } from 'zod';

export const AssignmentDeleteParamsSchema = AssignmentSchema.pick({
  assignment_id: true,
});
export type AssignmentDeleteParams = z.infer<typeof AssignmentDeleteParamsSchema>;

export type AssignmentDeleteResponse = {};

export type AssignmentDeleteEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/assignments-new/${AssignmentDeleteParams['assignment_id']}`,
  AssignmentDeleteResponse,
  AssignmentDeleteParams,
  undefined,
  undefined
>;
