import { z } from 'zod';
import { PositionPostgresSchema, SQLPositionExtended } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const PositionGetParamsSchema = PositionPostgresSchema.pick({
  position_id: true,
});
export type PositionGetParams = z.infer<typeof PositionGetParamsSchema>;

export type PositionGetResponse = SQLPositionExtended | null;

export type PositionGetEndpoint = Endpoint<
  Method.GET,
  `/api/v2//positions-new/${PositionGetParams['position_id']}`,
  PositionGetResponse,
  PositionGetParams,
  undefined,
  undefined
>;
