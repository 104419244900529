import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { PositionConflictSchema, SQLPositionSchema } from '../base';
import { AssignmentOverlapSchema } from '../../assignment';

export const PositionListConflictsAndOverlapsRequestSchema = SQLPositionSchema.pick({
  dtplannedend: true,
  dtplannedstart: true,
})
  .partial()
  .extend({
    positions_id: z.string().array(),
    is_sticky: z.boolean().nullish(),
  });
export type PositionListConflictsAndOverlapsRequest = z.infer<
  typeof PositionListConflictsAndOverlapsRequestSchema
>;

export const PositionListConflictsAndOverlapsResponseSchema = z.object({
  conflicts: z.array(PositionConflictSchema),
  overlaps: z.array(AssignmentOverlapSchema),
});

export type PositionListConflictsAndOverlapsResponse = z.infer<
  typeof PositionListConflictsAndOverlapsResponseSchema
>;

export type PositionListConflictsAndOverlapsEndpoint = Endpoint<
  Method.POST,
  `/api/v2/positions-new/conflicts-overlaps`,
  PositionListConflictsAndOverlapsResponse,
  undefined,
  undefined,
  PositionListConflictsAndOverlapsRequest
>;
