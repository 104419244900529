import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

import { TRecruiterFocusSchema } from '../table-schema';

export const RemoveRecruiterFocusSchema = TRecruiterFocusSchema.pick({
  recruiter_id: true,
});

export type RemoveRecruiterFocusParams = z.infer<typeof RemoveRecruiterFocusSchema>;

export type RemoveRecruiterFocusQuery = {};

export type RemoveRecruiterFocusRequest = {};

export type RemoveRecruiterFocusResponse = z.infer<typeof RemoveRecruiterFocusSchema>;

export type RemoveRecruiterFocusEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/sql/recruiter-focus/${RemoveRecruiterFocusParams['recruiter_id']}`,
  RemoveRecruiterFocusResponse,
  RemoveRecruiterFocusParams,
  RemoveRecruiterFocusQuery,
  RemoveRecruiterFocusRequest
>;
