import { z } from 'zod';
import { PositionPostgresSchema } from '../';

export const SQLPositionCreateSchema = PositionPostgresSchema.pick({
  project_id: true,
  isstaffit: true,
  islead: true,
  name: true,
  dtplannedstart: true,
  dtplannedend: true,
  inv_pos_name: true,
  target_cost: true,
  hire_group: true,
  seniority: true,
  english_level: true,
  hard_date: true,
  dt_staffit: true,
  is_open_to_sales: true,
}).partial({
  target_cost: true,
});
export type SQLPositionCreate = z.infer<typeof SQLPositionCreateSchema>;
export const PositionCreateSchema = SQLPositionCreateSchema;
export type PositionCreate = SQLPositionCreate;
