import { RevenueRateSchema } from '../../revenue-rate';
import { PositionCreateSchema } from '../crud';
import { SQLPosition } from '../base';
import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const PositionCreateRequestSchema = PositionCreateSchema.merge(
  RevenueRateSchema.pick({
    revenueamount: true,
  }).partial(),
).extend({
  skills_id: z.number().array(),
  use_skills_computed_name: z.boolean(),
});
export type PositionCreateRequest = z.infer<typeof PositionCreateRequestSchema>;

export type PositionCreateResponse = SQLPosition;

export type PositionCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2//positions-new/`,
  PositionCreateResponse,
  undefined,
  undefined,
  PositionCreateRequest
>;
