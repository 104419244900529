import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const DiscourseGetPersonInfoQuerySchema = z.object({
  email: z.string(),
});
export type DiscourseGetPersonInfoQuery = z.infer<typeof DiscourseGetPersonInfoQuerySchema>;

type PersonInfoKey = 'title' | 'department' | 'yoe' | 'yil';

type PersonInfoField<K extends PersonInfoKey, V> = {
  key: K;
  value: V;
  label: string;
};

export type DiscourseGetPersonInfoResponse = [
  PersonInfoField<'title', string>,
  PersonInfoField<'department', string>,
  PersonInfoField<'yoe', number>,
  PersonInfoField<'yil', number>,
];

export type DiscourseGetPersonInfoEndpoint = Endpoint<
  Method.GET,
  `/api/integrations/discourse/personInfo`,
  DiscourseGetPersonInfoResponse,
  undefined,
  DiscourseGetPersonInfoQuery,
  undefined
>;
