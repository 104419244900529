import { AppDetailSchema } from '../../application';
import { InterviewCalendarEventSchema } from '../../interview-calendar-event';
import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { InterviewRequestStage } from '../../interview-request';

export const IRDashboardReportListItemSchema = AppDetailSchema.pick({
  application_id: true,
  applicant_id: true,
  name: true,
  yoe: true,
  availability: true,
  country_code: true,
  lead_experience: true,
  timezone_short: true,
  expected_contract_type: true,
  current_status_ref: true,
  seniority: true,
  seniority_ref: true,

  cost: true,
  currency_salary: true,
  salary_currency: true,
  usd_salary: true,

  job_id: true,
  job_name: true,
  job_short_name: true,

  recruiter_name: true,
  recruiter_id: true,
})
  .merge(
    InterviewCalendarEventSchema.pick({
      dt_start: true,
      dt_end: true,
      act_recruiter_id: true,
      status: true,
    }),
  )
  .extend({
    act_recruiter_name: z.string().nullable(),
    description: z.string(),
    stage: z.nativeEnum(InterviewRequestStage),
    app_status: AppDetailSchema.shape.status,
  });
export type IRDashboardReportListItem = z.infer<typeof IRDashboardReportListItemSchema>;

export type IRDashboardReportListResponse = IRDashboardReportListItem[];

export type IRDashboardReportListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/interview-requests/dashboard-report-list`,
  IRDashboardReportListResponse,
  undefined,
  undefined,
  undefined
>;
