import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

import { TRecruiterFocus, TRecruiterFocusSchema } from '../table-schema';

export const GetRecruiterFocusSchema = TRecruiterFocusSchema.pick({
  recruiter_id: true,
});

export type GetRecruiterFocusParams = z.infer<typeof GetRecruiterFocusSchema>;

export type GetRecruiterFocusQuery = {};

export type GetRecruiterFocusRequest = {};

export type GetRecruiterFocusResponse = TRecruiterFocus;

export type GetRecruiterFocusEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/recruiter-focus/${GetRecruiterFocusParams['recruiter_id']}`,
  GetRecruiterFocusResponse,
  GetRecruiterFocusParams,
  GetRecruiterFocusQuery,
  GetRecruiterFocusRequest
>;
