import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TJobCategorySchema, TJobCategory } from '../../jobs/table-schemas';

export const JobCategoryUpdateSchema = TJobCategorySchema.pick({
  name: true,
});

export const JobCategoryUpdateParamsSchema = z.object({
  job_category_id: z.coerce.number(),
});

export type JobCategoryUpdate = z.infer<typeof JobCategoryUpdateSchema>;

export type UpdateJobCategoryParams = z.infer<typeof JobCategoryUpdateParamsSchema>;

export type UpdateJobCategoryQuery = {};

export type UpdateJobCategoryRequest = z.infer<typeof JobCategoryUpdateSchema>;

export type UpdateJobCategoryResponse = TJobCategory;

export type UpdateJobCategoryEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql/job-categories/${UpdateJobCategoryParams['job_category_id']}`,
  UpdateJobCategoryResponse,
  UpdateJobCategoryParams,
  UpdateJobCategoryQuery,
  UpdateJobCategoryRequest
>;
