import { z } from 'zod';

import {
  Endpoint,
  Method,
  makeSorter,
  FilterOperations,
  ReportsQuerySchema,
  ReportResponse,
} from '../../../infrastructure';

import { JobStatusSchema, OwnerExpandedSchema } from '../base';
import { TJobSchema, TJobCategorySchema, TJobPrioritySchema } from '../table-schemas';

export const ListJobsGroupedByFamilyJobItemSchema = TJobSchema.pick({
  job_id: true,
  title: true,
  job_suffix: true,
  slug: true,
  job_short_name: true,
  level: true,
  dtcreate: true,
  dtupdate: true,
}).extend({
  priority: TJobPrioritySchema.shape.name, // Hot, Ev
  status: JobStatusSchema,
  on_deck_visibility: z.boolean(),
  owner: OwnerExpandedSchema.nullable(),
  application_counts: z.object({
    application_count: z.number(),
    stage1_count: z.number(),
    stage2_count: z.number(),
    stage3_count: z.number(),
    stage4_count: z.number(),
    stage5_count: z.number(),
    on_deck_count: z.number(),
  }),
});

export type ListJobsGroupedByFamilyJobItem = z.infer<typeof ListJobsGroupedByFamilyJobItemSchema>;

export const ListJobsGroupedByFamilyItemSchema = z.object({
  job_family_id: z.number(),
  title: z.string(),
  category: TJobCategorySchema.shape.name.nullable(),
  sub_category: TJobCategorySchema.shape.name.nullable(),
  num_jobs: z.number(),
  num_applications: z.number(),
  jobs: z.array(ListJobsGroupedByFamilyJobItemSchema),
});

export type ListJobsGroupedByFamilyItem = z.infer<typeof ListJobsGroupedByFamilyItemSchema>;

export const ListJobsGroupedByFamilyFiltersSchema = z
  .object({
    job_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    level_ids: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    category_ids: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    status: z.record(z.enum([FilterOperations.IN]), JobStatusSchema.array()),
  })
  .partial();

export type ListJobsGroupedByFamilyFilters = z.infer<typeof ListJobsGroupedByFamilyFiltersSchema>;

export const ListJobsGroupedByFamilyJobsPageFiltersSchema = z
  .object({
    job_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    level_ids: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    category_ids: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    status: z.record(z.enum([FilterOperations.IN]), JobStatusSchema.array()),
  })
  .partial();

export const ListJobsGroupedByFamilySorterSchema = makeSorter(
  z.enum(['title', 'category', 'num_jobs', 'num_applications']),
);
export type ListJobsGroupedByFamilySorter = z.infer<typeof ListJobsGroupedByFamilySorterSchema>;

export const ListJobsGroupedByFamilyQuerySchema = ReportsQuerySchema.extend({
  filters: ListJobsGroupedByFamilyFiltersSchema.optional(),
  sorter: ListJobsGroupedByFamilySorterSchema.optional(),
});
export type ListJobsGroupedByFamilyQuery = z.infer<typeof ListJobsGroupedByFamilyQuerySchema>;

export type ListJobsGroupedByFamilyResponse = ReportResponse<ListJobsGroupedByFamilyItem>;

export type ListJobsGroupedByFamilyEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/jobs/grouped-by-family',
  ListJobsGroupedByFamilyResponse,
  {},
  ListJobsGroupedByFamilyQuery,
  {}
>;
